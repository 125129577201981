export const msalConfig = {
    auth: {
        clientId: "45702f01-fe0c-4579-ae5e-102555770024",
        authority: "https://login.microsoftonline.com/6b18947b-63e7-4323-b637-418f02655a69",
        knownAuthorities: ["https://login.microsoftonline.com/6b18947b-63e7-4323-b637-418f02655a69"],
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

export const logoutRequest = {
    authority: "https://login.microsoftonline.com/6b18947b-63e7-4323-b637-418f02655a69",
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL,
    mainWindowRedirectUri: process.env.REACT_APP_REDIRECT_URL,
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com"
};