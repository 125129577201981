import React from "react";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import LoggedIn from "./LoggedIn";
import LoggedOut from "./LoggedOut";
import {useQuery} from "../common/Utilities";

export default function Profile() {
    let query = useQuery();
    return (<>
        <AuthenticatedTemplate>
            <LoggedIn redirect={query.get("redirect")}/>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <LoggedOut/>
        </UnauthenticatedTemplate>
    </>)
}