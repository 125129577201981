import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card";
import {AdminHeader, UserHeader} from "./Headers";
import {faQrcode} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function AdminAbout() {
    return (
        <>
            <AdminHeader/>
            <Container className={'float-paper-el'}>
                <Row>
                    <h4>About</h4>
                    <hr/>
                    <p>Welcome to AnswerMe, a IDC survey creation tool.</p>
                    <p>Use the tool to create internal and external surveys, with customers, consumers, market surveys,
                        employees, events attendees, etc.
                    </p>
                    <p>
                        Share surveys and results with IDC members as you wish. <b>ATTENTION:</b> be up to date with
                        <a href={'https://www.idc.com/about/privacy'}> IDC's privacy policies </a> when preparing your
                        survey and in the way of handling and sharing data from respondents, respecting the rules of
                        your region.
                    </p>
                    <p>
                        This is a tool developed by IDC's Latin American IT development team.
                        For technical issues or questions about the tool, please contact us
                        at <a href={"mailto:answerme@idc.com"}>answerme@idc.com</a> or
                        at <a href={"mailto:latinitdevteamgrp@idc.com"}>latinitdevteamgrp@idc.com</a>.
                    </p>
                </Row>
                <Row>
                    <h4>Changelog</h4>
                    <hr/>
                    <Row xs={2} md={2} className={"gy-3"}>
                        <Col>
                            <Card>
                                <Card.Header className={"bg-primary text-white"}>v1.3.15 | Aug/21/2024</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Bugfixes and improvements:
                                        <ul>
                                            <li>
                                                Fixed a bug where authenticated (IDC) users wouldn't be able to finish a
                                                survey.
                                            </li>
                                            <li>
                                                Improved application resiliency.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.14 | Aug/20/2024</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Bugfixes and improvements:
                                        <ul>
                                            <li>
                                                Fixed a bug where the report wouldn't load all answers if the questions
                                                used with nested panels.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.13 | Aug/02/2024</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Bugfixes and improvements:
                                        <ul>
                                            <li>
                                                Updated system components.
                                            </li>
                                            <li>
                                                Improved application resiliency.
                                            </li>
                                            <li>
                                                Minor adjustments in the application theme.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.12 | Jun/20/2024</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Bugfixes and improvements:
                                        <ul>
                                            <li>
                                                Fixed an issue with links to media in generated Excel reports.
                                            </li>
                                            <li>
                                                Improved the error messages shown when accessing "Draft" or "Completed"
                                                surveys.
                                            </li>
                                            <li>
                                                Updated system components.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.11 | Apr/02/2024</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Bugfixes and improvements:
                                        <ul>
                                            <li>
                                                Fixed random URL generation in new surveys.
                                            </li>
                                            <li>
                                                Enabled random URL generation for existing surveys.
                                            </li>
                                            <li>
                                                Fixed reports for "Multi-Select Matrix": Report creation would skip
                                                certain questions if the column's cell type was different from the
                                                question's cell type.
                                            </li>
                                            <li>
                                                Allow users to select the language manually.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.10 | Mar/27/2024</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Bugfixes and improvements:
                                        <ul>
                                            <li>
                                                Fixed a regression in localization support. The options for selecting a
                                                new language were incorrectly disabled.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.9 | Jan/11/2024</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Bugfixes and improvements:
                                        <ul>
                                            <li>
                                                Fixed an edge case where certain question names wouldn't show up in
                                                reports.
                                            </li>
                                            <li>
                                                Reports now properly order questions according to their page and page
                                                position.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.8 | Nov/28/2023</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Bugfixes and improvements:
                                        <ul>
                                            <li>
                                                Fixed edge cases where URL validation would fail, leading to duplicated
                                                URL entries.
                                            </li>
                                            <li>
                                                Automatic versioning caused by survey changes was improved: Now only
                                                changes to survey questions will create a new version. Smaller changes
                                                (such as title or description changes) won't trigger it.
                                            </li>
                                            <li>
                                                The logged-on user is no longer assigned in answers when the survey type
                                                is "Public", making them truly anonymous. This change only affects IDC
                                                users.
                                            </li>
                                            <li>
                                                Cloning a survey now requires prompt confirmation.
                                            </li>
                                            <li>
                                                The survey list had its navigation improved for smaller screens, with
                                                word wrapping and collapsible menu buttons.
                                            </li>
                                            <li>
                                                The survey list also had its navigation improved for bigger screens,
                                                showing more content at once.
                                            </li>
                                        </ul>
                                        New features:
                                        <ul>
                                            <li>
                                                Replaced URL action buttons with "<i
                                                className={'dx-icon-overflow'}/> URL Options".
                                                <ul>
                                                    <li>
                                                        "<i className={'dx-icon-copy dx-link'}/> Copy" and
                                                        "<i className={'dx-icon-link dx-link'}/> Open" are
                                                        now under URL Options.
                                                    </li>
                                                    <li>
                                                        Added "<FontAwesomeIcon icon={faQrcode}/> QR code" for URLs.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Replaced "Logs and History" tab from Survey Edit with:
                                                <ul>
                                                    <li>
                                                        "<i className={'dx-icon-detailslayout dx-link'}/> Logs"
                                                        button on Survey Details. This shows the same log list from the
                                                        former "Logs and History".
                                                    </li>
                                                    <li>
                                                        A version selector under "Questionnaire", allowing you to
                                                        see all changes made in a single place. This replaces the survey
                                                        viewer from "Version History" in "Logs and History".
                                                    </li>
                                                    <li>
                                                        A version selector for Reports under "Results", allowing
                                                        you to see all answers in a single place. This replaces the
                                                        answer viewer from "Version History" in "Logs and History".
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.7 | Sep/22/2023</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Hotfix release:
                                        <ul>
                                            <li>
                                                Allow local user search in "Share with..." and "Group Management" if
                                                Azure AD is inaccessible.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.6 | Jul/18/2023</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Fixed bugs:
                                        <ul>
                                            <li>Fixed an issue where public surveys would check if the logged user
                                                already answered it. Originally fixed in v1.3.0 but not properly
                                                implemented.
                                            </li>
                                            <li>
                                                Fixed an issue where "Multi-Select Matrix" wouldn't be properly parsed
                                                in the report.
                                            </li>
                                        </ul>
                                        New features:
                                        <ul>
                                            <li>
                                                Added support for the widgets: "Signature", "Dynamic Panel", and "File
                                                Upload".
                                            </li>
                                            <li>
                                                Added previews in the report for "Image Picker".
                                            </li>
                                            <li>
                                                Added links to images, videos and files to the Excel report, if the
                                                widget uses it.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.5 | Jun/16/2023</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Fixed bugs:
                                        <ul>
                                            <li>
                                                Fixed an issue where answers from "Panel" questions wouldn't appear in
                                                the report.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.4 | May/24/2023</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        New features:
                                        <ul>
                                            <li>
                                                Added "Receive a copy of the email sent" to action "Send an email to the
                                                user on complete".
                                            </li>
                                            <ul>
                                                <li>
                                                    This option allows the user to receive a BCC copy of the email sent
                                                    to the respondent.
                                                </li>
                                                <li>
                                                    The default behaviour for older surveys will be to not
                                                    notify.
                                                </li>
                                                <li>
                                                    This option follows the rules set in the options below and to avoid
                                                    breakage in existing email rules, it will lead to duplicated emails
                                                    ("New Answer" + the email copy).<br/>
                                                    Please send your feedback to the LA IT Development Team
                                                    if you believe this is undesired.
                                                </li>
                                                <ul>
                                                    <li>
                                                        "Send an email notification whenever the survey is completed" in
                                                        "Survey Details".
                                                    </li>
                                                    <li>
                                                        "Receive email notifications" under "Share with..." -> "Survey
                                                        was shared with".
                                                    </li>
                                                    <li>
                                                        Any group added to the survey, where "Receive email
                                                        notifications" was enabled for the user in the group.
                                                    </li>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.3 | May/19/2023</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Fixed bugs:
                                        <ul>
                                            <li>
                                                Fixed an issue where "Action failed" notifications wouldn't notify
                                                shared users nor obey "Send an email notification whenever the survey is
                                                completed".
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.2 | May/18/2023</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Fixed bugs:
                                        <ul>
                                            <li>
                                                Fixed an issue where the value for boolean (Yes/No) questions would
                                                always show as "No" in reports.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.1 | Nov/18/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        New features:
                                        <ul>
                                            <li>
                                                It is now possible to disable email notifications for survey creators.
                                            </li>
                                            <li>It is now possible to remove an survey action.</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.3.0 | Nov/05/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Fixed bugs:
                                        <ul>
                                            <li>Fixed an issue where adding a group to a survey would leave the
                                                interface in a partial state.
                                            </li>
                                            <li>Fixed an issue where public surveys would check if the logged user
                                                already answered it.
                                            </li>
                                        </ul>
                                        New features:
                                        <ul>
                                            <li>Underscores and hyphens are now supported in survey URLs.</li>
                                            <li>Added a button to clone surveys.</li>
                                            <li>Added a tab "Logs and History" to Survey Details.</li>
                                            <ul>
                                                <li>Logs keep track of all changes applied to the survey information.
                                                </li>
                                                <li>History keeps track of all changes applied to the survey content
                                                    after it has been set to "In Progress" once.
                                                </li>
                                                <ul>
                                                    <li>It is now possible to edit a survey while it is "In Progress".
                                                    </li>
                                                    <li>It is now possible to see the survey's previous state and the
                                                        answers from that time.
                                                    </li>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.2.4 | Oct/19/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        New features:
                                        <ul>
                                            <li>Shared users can now receive the same notifications as the creator.</li>
                                            <ul>
                                                <li>Set "Receive email notifications" to "Yes" on "Share with..." or
                                                    "Manage Groups..."
                                                </li>
                                                <li>For this update, we are automatically setting it to "Yes" to all.
                                                </li>
                                            </ul>
                                        </ul>
                                        Fixed bugs:
                                        <ul>
                                            <li>Fixed an issue where you couldn't delete users.</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.2.3 | Sep/30/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        New features:
                                        <ul>
                                            <li>You can now use HTML markup in the survey's title, description,
                                                questions and answers.
                                            </li>
                                            <li>
                                                Added contact information and a link to our privacy policy for users.
                                            </li>
                                            <li>Added Google Analytics.</li>
                                        </ul>
                                        Fixed bugs:
                                        <ul>
                                            <li>Fixed an issue where answers wouldn't load when their field name
                                                contained certain special characters.
                                            </li>
                                            <li>
                                                Minor UX changes:
                                            </li>
                                            <ul>
                                                <li>Fix incorrect "AnswerMe" text color on header.</li>
                                                <li>Improve visuals for header bar.</li>
                                                <li>Improved contrast in logout button.</li>
                                                <li>Increase the margin between the survey's title and its
                                                    description.
                                                </li>
                                            </ul>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.2.2 | Sep/23/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Fixed bugs:
                                        <ul>
                                            <li>Fixed an issue where survey details wouldn't load if a survey had no
                                                questions but had a title or parameter.
                                            </li>
                                            <li>
                                                Improved response time and stability when sending an email to a user.
                                            </li>
                                            <li>
                                                Improved response time when searching for users (User Share/Group
                                                Management).
                                            </li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.2.1 | Sep/12/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        New features:
                                        <ul>
                                            <li>"My Profile" page, where you can manage your settings.</li>
                                            <li>Notifications are sent when an user finishes a survey.</li>
                                            <li>Actions to be executed after finishing a survey.</li>
                                            <ul>
                                                <li>Currently available action: "Send user an email on complete".</li>
                                            </ul>
                                            <li>Improved permissions for shares: "View" and "View and Edit".</li>
                                            <li>Share surveys with groups.</li>
                                            <ul>
                                                <li>To create and manage groups, use "My Profile".</li>
                                            </ul>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.2.0 | Aug/24/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        New features:
                                        <ul>
                                            <li>An improved design for the main interface.</li>
                                            <li>Sharing surveys with users.</li>
                                            <li>About page.</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.1.1 | Jul/12/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Bugfix release for survey management.<br/>
                                        New features:
                                        <ul>
                                            <li>Reports handle questions with "Choices From" on surveys.</li>
                                            <li>Report format was updated to allow usage of PivotTable on
                                                multiple-choice questions.
                                            </li>
                                        </ul>
                                        Fixes:
                                        <ul>
                                            <li>Fix "Add Question" button not working properly.</li>
                                            <li>"Last Updated at" shows time.</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.1.0 | May/27/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        New features:
                                        <ul>
                                            <li>Add a new type for surveys: "Internal (IDC)"</li>
                                            <li>SSO login for IDC employees on surveys.</li>
                                            <li>Add a proper error page for surveys.</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>v1.0.0 | May/16/2022</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Initial release:
                                        <ul>
                                            <li>Survey Management.</li>
                                            <li>Public Surveys.</li>
                                            <li>Metrics.</li>
                                            <li>Results.</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </>
    )
}

export function UserAbout() {
    return (
        <>
            <UserHeader/>
            <Container className={'float-paper-el'}>
                <Row>
                    <h4>About</h4>
                    <hr/>
                    <p>Welcome to AnswerMe, a IDC survey tool. This is a tool developed by IDC's Latin American IT
                        development team.</p>
                    <p>
                        Please contact IDC’s Privacy Compliance Officer at <a
                        href={"mailto:privacy@idc.com"}>privacy@idc.com</a> for all matters related to
                        privacy and usage of your data. You can find our privacy policies <a
                        href={'https://www.idc.com/about/privacy'}>here</a>.
                    </p>
                    <p>
                        For technical issues or questions about the tool, please contact us at <a
                        href={"mailto:answerme@idc.com"}>answerme@idc.com</a>.
                    </p>
                </Row>
            </Container>
        </>
    )
}