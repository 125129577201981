import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axios from "axios";
import notify from "devextreme/ui/notify";
import * as Survey from "survey-react";
// import "survey-react/modern.css";
import "survey-react/survey.css";
import {UserHeader} from "../common/Headers";
import ErrorMessage from "../common/ErrorMessage";
import {getCookie, getSurveyLocales, getUserLocale, useAuthToAPI} from "../common/Utilities";
import SelectBox from "devextreme-react/select-box";

const SURVEY_FETCH_URL = process.env.REACT_APP_API_URL + '/api/v1/survey/survey/';
const SURVEY_ANSWER_URL = process.env.REACT_APP_API_URL + '/api/v1/survey/state/';
const SURVEY_ACTION_URL = (id) => {
    return `${SURVEY_ANSWER_URL}${id}/do_action/`
};

export default function UserSurvey() {
    Survey.StylesManager.applyTheme('default');
    const AXIOS_HEADERS = getCookie('csrftoken') ? {
        headers: {
            'X-CSRFToken': getCookie('csrftoken')
        }
    } : null;
    const [id, setId] = useState(useParams().id);
    const [survey, setSurvey] = useState({});
    const [answer, setAnswer] = useState({});
    const [error, setError] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [locale, setLocale] = useState("");
    const [localeData, setLocaleData] = useState({});
    const model = new Survey.Model(survey);
    model.locale = locale;
    model.data = answer;
    model.sendResultOnPageNext = true; // Saves data on page change.
    // let converter = new showdown.Converter();
    model.onTextMarkdown.add((survey, options) => {
        // let str = converter.makeHtml(options.text);
        // str = str.substring(3);
        // str = str.substring(0, str.length - 4);
        options.html = options.text;
    });
    model.onComplete.add((survey) => {
        axios.post(SURVEY_ANSWER_URL, {
            answer: survey.data,
            is_completed: true,
            survey: id
        }, AXIOS_HEADERS).then((res) => {
            notify('Data was saved! Thank you for your time!', 'success', 5000);
            axios.post(SURVEY_ACTION_URL(id), {
                action: 'default',
                id: res.data.id
            }, AXIOS_HEADERS);
        }).catch((err) => {
            notify('Something went wrong! Data wasn\'t saved.', 'error', 5000);
            console.error(err.response);
            axios.post(SURVEY_ACTION_URL(id), {
                action: 'error',
                details: err.toJSON()
            }, AXIOS_HEADERS);
        });
    });
    model.onPartialSend.add((survey) => {
        // Saves whenever the survey's page changes.
        // It will fail silently, unlike the method on onComplete;
        if (!isAuthenticated)
            return
        axios.post(SURVEY_ANSWER_URL, {
            answer: survey.data,
            is_completed: false,
            survey: id
        }, AXIOS_HEADERS);
    });
    useEffect(() => {
        // Load survey from URL params.
        axios.get(`${SURVEY_FETCH_URL}${id}/`).then((res) => {
            if (res.data.detail) {
                setError({
                    status: res.status,
                    message: res.data.detail ? res.data.detail : res.statusText,
                    url: window.location.pathname,
                });
            } else {
                setSurvey(res.data.survey);
            }
        }).catch((err) => {
            console.error(err.response);
            setError({
                status: err.response.status,
                message: err.response.data.detail ? err.response.data.detail : err.response.statusText,
                url: window.location.pathname
            });
        });
    }, []);
    useEffect(() => {
        let userLocale = getUserLocale(model.getUsedLocales());
        setLocaleData(getSurveyLocales(model.getUsedLocales(), model.getLocale()));
        if (model.getUsedLocales().includes(userLocale))
            setLocale(userLocale);
    }, [survey]);
    useAuthToAPI(() => {
        setIsAuthenticated(true);
        axios.get(`${SURVEY_ANSWER_URL}${id}/`).then((res) => {
            setAnswer(res.data.answer);
        }).catch((err) => {
            if (err.response.status !== 404) {
                console.error(err)
            }
        });
    }, [], false);
    return (
        <>
            <UserHeader/>
            <Container className={'float-paper-el'}>
                <Row>
                    {survey
                        && Object.keys(survey).length !== 0
                        && <SelectBox dataSource={localeData}
                                      label={"Language"}
                                      labelMode={"static"}
                                      valueExpr={"id"}
                                      displayExpr={"name"}
                                      value={locale}
                                      visible={localeData.length >= 2}
                                      onValueChanged={(e) => {
                                          setLocale(e.value);
                                      }}/>}
                </Row>
                <Row>
                    {error
                        && Object.keys(error).length !== 0
                        && <ErrorMessage hideBackground={true} redirect={error.url} msg={error.message} status={error.status}/>}
                    {survey
                        && Object.keys(survey).length !== 0
                        && <Survey.Survey model={model}/>}
                </Row>
            </Container>
        </>
    )
}
