import React, {useRef} from "react";
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import {DataGrid, SearchPanel, Grouping, Export, Item, Toolbar, Column} from "devextreme-react/data-grid";
import {exportDataGrid} from 'devextreme/excel_exporter';
import {ANSWER_FILE_API} from "../../DataSources";

function mediaTemplate(cellData) {
    if (cellData.value === undefined || cellData.value === null)
        return null
    let data = JSON.parse(cellData.value)
    switch (data.type) {
        case "image":
            return (<img src={data.src} alt={data.alt}/>)
        case "video":
            return (<video src={data.src} controls={true} height={320} width={240}>{data.alt}</video>)
        case "youtube":
            return (<a href={data.src}>{data.alt}</a>)
        case "download":
            return (<a href={data.src} download={data.alt} target={"_blank"}>{data.alt}</a>)
        default:
            return <div>{data.alt}</div>
    }
}


function parseColumns(data) {
    let columns = [];
    let customized_fields = [];
    data.forEach((column, i) => {
        if (Object.hasOwn(column, 'cellRender')) {
            customized_fields.push({'field': column.dataField, 'render': column.cellRender});
            switch (column.cellRender) {
                case 'mediaTemplate':
                    columns.push(<Column key={`${i}_${column.dataField}`}
                                         dataField={column.dataField}
                                         caption={column.caption}
                                         fixed={column.fixed}
                                         cellRender={mediaTemplate}/>)
                    break;
                default:
                    columns.push(<Column key={`${i}_${column.dataField}`}
                                         dataField={column.dataField}
                                         caption={column.caption}
                                         fixed={column.fixed}/>)
                    break;
            }
        } else {
            columns.push(<Column key={`${i}_${column.dataField}`}
                                 dataField={column.dataField}
                                 caption={column.caption}
                                 fixed={column.fixed}/>)
        }
    })
    return {
        'columns': columns,
        'customized_fields': customized_fields
    };
}

export default function ViewAnswers(props) {
    const gridRef = useRef(null);
    const {columns, customized_fields} = parseColumns(props.columns);
    return (
        <>
            <DataGrid dataSource={props.data}
                      ref={gridRef}
                      columnAutoWidth={true}
                      width={"100%"}
                      onExporting={(e) => {
                          const workbook = new Workbook();
                          const worksheet = workbook.addWorksheet("Main sheet");
                          exportDataGrid({
                              worksheet: worksheet,
                              component: e.component,
                              customizeCell: ({gridCell, excelCell}) => {
                                  if (gridCell.rowType === 'data' && gridCell.value !== null && gridCell.value !== undefined) {
                                      let t = customized_fields.find(x => x.field === gridCell.column.dataField);
                                      if (t !== undefined) {
                                          console.log(gridCell.value);
                                          let data = JSON.parse(gridCell.value);
                                          excelCell.font = {
                                              color: {argb: 'FF0000EE'},
                                              italic: true,
                                              underline: 'single'
                                          }
                                          switch (t.render) {
                                              case 'mediaTemplate':
                                                  switch (data.type) {
                                                      case "image":
                                                      case "video":
                                                      case "youtube":
                                                      case "download":
                                                          let id = encodeURIComponent(gridCell.data['!answer_id']);
                                                          let fd = encodeURIComponent(gridCell.column.dataField)
                                                          excelCell.value = {
                                                              text: data.alt,
                                                              hyperlink: `${ANSWER_FILE_API}?id=${id}&fd=${fd}`
                                                          }
                                                          break;
                                                      default:
                                                          excelCell.value = {
                                                              text: data.alt,
                                                          }
                                                          break;
                                                  }
                                                  break;
                                          }
                                      }
                                  }
                              }
                          }).then(function () {
                              workbook.xlsx.writeBuffer().then(function (buffer) {
                                  saveAs(new Blob([buffer],
                                          {type: "application/octet-stream"}),
                                      `[${props.survey_name}] AnswerMe Report - Metrics Per User.xlsx`);
                              });
                          });
                          e.cancel = true;
                      }}
            >
                <Toolbar>
                    <Item name={"exportButton"}
                          showText={"always"}
                          widget={'dxButton'}
                          options={{
                              name: "exportButton",
                              text: "Export",
                              icon: 'exportxlsx',
                              type: 'default',
                              stylingMode: 'outlined',
                          }}/>
                    <Item name={"searchPanel"}/>
                </Toolbar>
                <Export enabled={true}/>
                <SearchPanel visible={true} highlightCaseSensitive={true}/>
                <Grouping contextMenuEnabled={true}/>
                {columns}
            </DataGrid>
        </>
    )
}
