import React, {useRef, useState} from "react";
import {Column, DataGrid, Lookup, SearchPanel, Item, Toolbar, Scrolling} from "devextreme-react/data-grid";
import {Popup} from 'devextreme-react/popup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {USER_STORE, SURVEY_STORE, STATUS_STORE, TYPE_STORE} from "./DataSources";
import {AdminHeader} from "../common/Headers";
import SurveyInformation from "./edit/SurveyInformation";
import {useAuthToAPI, getCookie} from "../common/Utilities";
import LoadPanel, {Position} from "devextreme-react/load-panel";

export default function SurveyList() {
    const [show, setShow] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const AXIOS_HEADERS = {
        headers: {
            'X-CSRFToken': getCookie('csrftoken')
        }
    }
    const createSurvey = () => {
        const empty_data = {
            name: null,
            notes: null,
            url: null,
            additional_actions: null,
            survey: {},
            status: 1,
            type: 1,
        };
        return (<SurveyInformation setShow={setShow} isEditing={true} data={empty_data} headers={AXIOS_HEADERS}/>)
    };
    const formatDate = (cell) => {
        if (cell.value === undefined || cell.value === null)
            return 'N/A';
        return cell.value.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            timeZone: 'UTC'
        }).replace(/(\s)|(,\s)/g, '/');
    }
    const gridRef = useRef(null);
    useAuthToAPI(() => {
        if (gridRef.current !== null) {
            gridRef.current.instance().refresh();
        }
        return setTimeout(() => {
            setIsAuthenticated(true)
        }, 800);
    }, [gridRef]);
    if (!isAuthenticated)
        return (<>
            <AdminHeader/>
            <LoadPanel hideOnOutsideClick={false}
                       visible={!isAuthenticated}>
                <Position at={"center"}/>
            </LoadPanel>
        </>)
    return (
        <>
            <AdminHeader/>
            <Container fluid className={'float-paper-el container-fluid-limiter'}>
                <Row>
                    <div className="AdminSurveyList">
                        <Popup title={"Create a new survey"}
                               hint={"New Survey"}
                               contentRender={createSurvey}
                               onHiding={() => {
                                   setShow(false)
                               }}
                               visible={show}
                               showTitle={true}
                               showCloseButton={true}
                               position={"top"}/>
                        {isAuthenticated &&
                            <DataGrid dataSource={SURVEY_STORE}
                                      ref={gridRef}
                                      columnAutoWidth={true}
                                      width={"100%"}
                                      height={"100vh"}
                                      wordWrapEnabled={true}>
                                <Toolbar>
                                    <Item location={"before"}
                                          locateInMenu={"auto"}
                                          showText={"inMenu"}
                                          widget={'dxButton'}
                                          options={{
                                              name: "refreshData",
                                              text: "Refresh",
                                              icon: 'refresh',
                                              type: 'default',
                                              stylingMode: 'outlined',
                                              onClick: () => {
                                                  gridRef.current.instance().refresh();
                                              },
                                          }}/>
                                    <Item location={"before"}
                                          locateInMenu={"auto"}
                                          showText={"always"}
                                          widget={'dxButton'}
                                          options={{
                                              name: "addNewSurvey",
                                              text: "New Survey",
                                              icon: 'add',
                                              type: 'default',
                                              stylingMode: 'outlined',
                                              onClick: () => {
                                                  setShow(true);
                                              },
                                          }}/>
                                    <Item name={"searchPanel"}/>
                                </Toolbar>
                                <Scrolling showScrollbar={'always'}>
                                </Scrolling>
                                <SearchPanel visible={true} highlightCaseSensitive={true}/>
                                <Column dataField={'id'}
                                        dataType={'number'}
                                        caption={'ID'}
                                        allowEditing={false}
                                        cellRender={(data) => {
                                            if (window.location.href.charAt(window.location.href.length - 1) !== '/')
                                                return (<a href={`${window.location.pathname}/${data.value}`}>
                                                    {data.value}
                                                </a>)
                                            else
                                                return (<a href={`${window.location.pathname}${data.value}`}>
                                                    {data.value}
                                                </a>)
                                        }}
                                />
                                <Column dataField={'name'} dataType={'string'} caption={'Internal Name'}/>
                                <Column dataField={'type'} dataType={'string'} caption={'Type'}>
                                    <Lookup dataSource={TYPE_STORE} valueExpr={'id'} displayExpr={'name'}/>
                                </Column>
                                <Column dataField={'status'} dataType={'object'} caption={'Status'}>
                                    <Lookup dataSource={STATUS_STORE} valueExpr={'id'} displayExpr={'name'}/>
                                </Column>
                                <Column dataField={'completed'} dataType={'number'} caption={'Answers'}
                                        allowEditing={false}/>
                                <Column dataField={'group'} dataType={'object'} caption={'Access Groups'}
                                        visible={false}/>
                                <Column dataField={'url'} dataType={'string'} caption={'Survey URL'} visible={false}/>
                                <Column dataField={'created_by'} dataType={'object'} caption={'Created by'}
                                        allowEditing={false}>
                                    <Lookup dataSource={USER_STORE} valueExpr={'id'} displayExpr={'name'}/>
                                </Column>
                                <Column dataField={'created_at'}
                                        dataType={'date'}
                                        caption={'Created at'}
                                        allowEditing={false}
                                        customizeText={formatDate}/>
                                <Column dataField={'updated_at'}
                                        dataType={'date'}
                                        caption={'Last Updated at'}
                                        allowEditing={false}
                                        customizeText={formatDate}/>
                            </DataGrid>}
                    </div>
                </Row>
            </Container>
        </>
    )
}
