import React from "react";
import {Route, Switch} from "react-router-dom";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import ReactGA from "react-ga4";
import UserSurvey from "./survey/UserSurvey";
import SurveyList from "./admin/SurveyList";
import SurveyEdit from "./admin/SurveyEdit";
import Help from "./admin/Help";
import LoginCallback from "./auth/LoginCallback";
import AuthProfile from "./auth/Profile";
import AdminProfile from "./admin/Profile"
import SignOut from "./auth/SignOut";
import {AdminAbout, UserAbout} from "./common/About";
import ErrorMessage from "./common/ErrorMessage";
import config from 'devextreme/core/config';
import {licenseKey} from "./common/DevExtremeConfig.ts";

ReactGA.initialize('G-SPYT729NHD'); // Google Analytics ID.
config({licenseKey}); // DevExpress license key.

function App() {
    return (
        <Switch>
            {/* Specific paths should be put before generic ones (ex: /survey/:id, then /survey/ */}
            <Route path={'/admin/auth/callback/'}>
                <LoginCallback/>
            </Route>
            <Route path={'/admin/about'}>
                <UnauthenticatedTemplate>
                    <ErrorMessage status={401} redirect={window.location.pathname}/>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <AdminAbout/>
                </AuthenticatedTemplate>
            </Route>
            <Route path={'/admin/profile/'}>
                <UnauthenticatedTemplate>
                    <ErrorMessage status={401} redirect={window.location.pathname}/>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <AdminProfile/>
                </AuthenticatedTemplate>
            </Route>
            <Route path={'/admin/survey/:id'}>
                <UnauthenticatedTemplate>
                    <ErrorMessage status={401} redirect={window.location.pathname}/>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <SurveyEdit/>
                </AuthenticatedTemplate>
            </Route>
            <Route path={'/admin/survey'}>
                <UnauthenticatedTemplate>
                    <ErrorMessage status={401} redirect={window.location.pathname}/>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <SurveyList/>
                </AuthenticatedTemplate>
            </Route>
            <Route path={'/admin/help'}>
                <UnauthenticatedTemplate>
                    <ErrorMessage status={401} redirect={window.location.pathname}/>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <Help/>
                </AuthenticatedTemplate>
            </Route>
            <Route path={'/about/'}>
                <UserAbout/>
            </Route>
            <Route path={'/survey/:id'}>
                <UserSurvey/>
            </Route>
            <Route path={'/signout/'}>
                <SignOut/>
            </Route>
            <Route path={'/'}>
                <AuthProfile/>
            </Route>
        </Switch>
    );
}

export default App;
