import React, {useState, useRef} from "react";
import LoadPanel, {Position} from "devextreme-react/load-panel";
import {SelectBox} from "devextreme-react/select-box";
import {TextBox, Button as TextBoxButton} from "devextreme-react/text-box";
import Stack from "react-bootstrap/Stack";
import {isEmptyObject} from "../../../common/Utilities";
import {AZURE_STORE} from "../../DataSources";

export default function AzureUserSearch(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [source, setSource] = useState({});
    const textRef = useRef(null);
    const selectRef = useRef(null);
    return (
        <>
            <LoadPanel hideOnOutsideClick={false}
                       visible={isLoading}>
                <Position at={"center"} of={"#searchStack"}/>
            </LoadPanel>
            <Stack id={"searchStack"}
                   gap={2}>
                <TextBox ref={textRef}
                         placeholder={"Type to search..."}>
                    <TextBoxButton name={"search"}
                                   location={"after"}
                                   options={{
                                       icon: "find",
                                       type: "default",
                                       onClick: () => {
                                           let options = {
                                               searchOperation: "contains",
                                               searchExpr: "name",
                                               searchValue: textRef.current.instance().option("text")
                                           };
                                           setIsLoading(true);
                                           AZURE_STORE.load(options).then((data) => {
                                               selectRef.current.instance().reset();
                                               setSource(data);
                                               setIsLoading(false);
                                           });
                                       }
                                   }}/>
                </TextBox>
                <SelectBox ref={selectRef}
                           dataSource={source}
                           placeholder={"Select a result"}
                           labelMode={"static"}
                           valueExpr={"id"}
                           displayExpr={"name"}
                           disabled={isEmptyObject(source)}
                           onValueChanged={(e) => {
                               if (e.value !== 'error')
                                   props.data.setValue(e.value);
                           }}/>
            </Stack>
        </>
    )
}