import React, {useRef, useState, useEffect} from "react";
import {ButtonItem, Form, GroupItem, SimpleItem} from "devextreme-react/form";
import {List} from "devextreme-react/list";
import {SURVEY_GROUP_STORE} from "../../DataSources";
import notify from "devextreme/ui/notify";

export default function ShareGroups(props) {
    const [availableGroups, setAvailableGroups] = useState({});
    const [selectedGroups, setSelectedGroups] = useState({});
    const [groupData, setGroupData] = useState({});
    const formRef = useRef(null);
    const dataStore = SURVEY_GROUP_STORE(props.id);
    useEffect(() => {
        dataStore.load().then((res) => {
            setAvailableGroups(res);
            setSelectedGroups(props.initial_groups);
        });
    }, [props.id, props.initial_groups]);
    const groupList = () => {
        return (
            <List dataSource={selectedGroups}
                  displayExpr={"name"}
                  selectionMode={"none"}
                  allowItemDeleting={true}
                  onItemDeleted={(e) => {
                      dataStore.remove(e.itemData.id).then((res) => {
                          setSelectedGroups(res.group);
                          dataStore.load().then((res) => {
                              setAvailableGroups(res);
                          });
                      }).catch((err) => {
                          console.error(err.response)
                          notify("Failed to delete group. Please contact the IT Team", "error", 5000);
                      })
                  }}/>
        )
    }
    return (
        <>
            <Form width={"100%"}
                  ref={formRef}
                  formData={groupData}
                  showValidationSummary={true}>
                <GroupItem colCount={5}>
                    <SimpleItem dataField={"add"}
                                label={{text: 'Add a group'}}
                                colSpan={4}
                                editorType={"dxSelectBox"}
                                editorOptions={{
                                    dataSource: availableGroups,
                                    valueExpr: "id",
                                    displayExpr: "name",
                                }}
                                isRequired={true}/>
                    <ButtonItem horizontalAlignment={"left"}
                                verticalAlignment={"bottom"}
                                colSpan={1}
                                buttonOptions={{
                                    icon: "add",
                                    text: "Add",
                                    type: "success",
                                    stylingMode: "outlined",
                                    onClick: (c) => {
                                        c.component.option("disabled", true);
                                        let result = formRef.current.instance().validate()
                                        if (result.isValid) {
                                            dataStore.insert(groupData).then((res) => {
                                                setSelectedGroups(res.group);
                                                dataStore.load().then((res) => {
                                                    setAvailableGroups(res);
                                                });
                                            }).catch((err) => {
                                                console.error(err.response)
                                                notify("Failed to save data. Please contact the IT Team", "error", 5000);
                                            })
                                        } else {
                                            c.component.option("disabled", false);
                                            notify("Inputted data is invalid. Please verify your fields", "error", 5000);
                                        }
                                    }
                                }}/>
                </GroupItem>
                <SimpleItem render={groupList}/>
            </Form>
        </>
    )
}