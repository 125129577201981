import React from "react";
import Button from "react-bootstrap/Button";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "./AuthCfg";


function handleLogout() {
    window.location.replace("/signout/");
}

function handleLogin(instance) {
    instance.loginRedirect(loginRequest);
}

export function SignOutButton() {
    return (
        <Button variant="logout" className="ml-auto" onClick={handleLogout}>Sign out</Button>
    );
}


export function SignInButton() {
    const {instance} = useMsal();
    return (
        <Button variant="primary" className="ml-auto app-border-radius" onClick={() => handleLogin(instance)}>Sign In</Button>
    );
}

export function GoBackButton(props) {
    return (
        <Button variant="primary" className="ml-auto app-border-radius"
                onClick={() => window.location.href(props.url)}>{props.text}</Button>
    );
}