import React, {useState} from "react";
import LoadPanel, {Position} from "devextreme-react/load-panel";
import Form, {ButtonItem, SimpleItem, GroupItem} from "devextreme-react/form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axios from "axios";
import {useAuthToAPI, isEmptyObject, getCookie} from "../common/Utilities";
import {PROFILE_API} from "./DataSources";
import {AdminHeader} from "../common/Headers";
import ListGroups from "./groups/ListGroups";

export default function Profile(props) {
    const AXIOS_HEADERS = {
        headers: {
            'X-CSRFToken': getCookie('csrftoken')
        }
    };
    const [user, setUser] = useState({});
    useAuthToAPI(() => {
        axios.get(PROFILE_API).then(res => setUser(res.data)).catch(err => console.error(err));
    }, []);
    if (isEmptyObject(user)) {
        return (<>
            <AdminHeader/>
            <LoadPanel hideOnOutsideClick={false}
                       visible={isEmptyObject(user)}>
                <Position at={"center"}/>
            </LoadPanel>
        </>);
    }
    return (<>
        <AdminHeader/>
        <Container className={'float-paper-el'}>
            <Row className={"gy-3"}>
                <h4>Welcome, {user.name}</h4>
                <hr/>
                <p>You can find some settings for your profile below.</p>
            </Row>
            <Row>
                <Form visible={user.admin !== undefined}
                      formData={user.admin}>
                    <GroupItem caption={"Admin Settings"}>
                        <SimpleItem dataField={"list"}
                                    editorType={'dxCheckBox'}
                                    label={{text: "Show all surveys with full access: ", location: "left"}}/>
                        <ButtonItem horizontalAlignment={'right'}
                                    buttonOptions={{
                                        icon: 'save',
                                        text: 'Save',
                                        type: 'success',
                                        stylingMode: 'outlined',
                                        onClick: (c) => {
                                            c.component.option('disabled', true);
                                            axios.post(PROFILE_API, user.admin, AXIOS_HEADERS).then(res => {
                                                c.component.option('disabled', false);
                                                setUser(res.data);
                                            }).catch(err => console.error(err));
                                        }
                                    }}/>
                    </GroupItem>
                </Form>
            </Row>
            <Row>
                <h4>Your Groups</h4>
                <hr/>
                <ListGroups/>
            </Row>
        </Container>
    </>)
}