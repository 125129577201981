import React, {useRef} from "react";
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button'
import QRCode from "react-qr-code";
import notify from "devextreme/ui/notify";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

async function downloadQrCode(ref, filename) {
    if (!ref.current) {
        notify('Unable to download QR Code.', 'warning', 5000);
    } else {
        const svgData = new XMLSerializer().serializeToString(ref.current);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width + 40;
            canvas.height = img.height + 40;
            ctx.strokeRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = "#FFFFFF";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 20, 20);
            const pngFile = canvas.toDataURL("image/png", 1.0);

            const downloadLink = document.createElement("a");
            downloadLink.download = [null, undefined].includes(filename) ? "qrcode" : String(filename);
            downloadLink.href = `${pngFile}`;
            downloadLink.target = "_blank";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            downloadLink.remove();
        }
        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }
}

export default function QRCodeBuilder(props) {
    const qrRef = useRef(null);
    return (<>
        <Stack gap={2}>
            <QRCode size={256} style={{width: "100%"}} value={props.value} ref={qrRef}/>
            <Button variant="success" className="ml-auto app-border-radius"
                    onClick={() => {
                        downloadQrCode(qrRef, props.filename)
                    }}><FontAwesomeIcon icon={faDownload}/> Download QR Code</Button>
        </Stack>
    </>)
}