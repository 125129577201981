import React from "react";
import axios from "axios";
import * as Creator from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-core/survey.i18n";
import "survey-creator-core/survey-creator-core.min.css";
import "./SurveyCreator.css";
import notify from 'devextreme/ui/notify';
import {SURVEY_API} from "../DataSources";

export default function SurveyCreator(props) {
    const OPTIONS = {
        showTranslationTab: true,
        showJSONEditorTab: false,
        showTestSurveyTab: false,
        showLogicTab: false,
        generateValidJSON: true,
        showOptions: false,
        haveCommercialLicense: true, // We have a license under rfaim@idc.com's account.
        isAutoSave: false, // Having issues with autosaving and renders.
        showToolbox: false, // This flag is not working. SurveyCreator.css has a workaround.
    };
    const creator = new Creator.SurveyCreator(OPTIONS);
    creator.JSON = props.survey;
    creator.saveSurveyFunc = (saveNo, callback) => {
        axios.patch(SURVEY_API + `${props.id}/`, {
            survey: creator.JSON
        }, props.headers).then((res) => {
            notify('Changes were saved successfully.', 'success', 5000);
            props.setData(res.data);
            callback(saveNo, true);
        }).catch((err) => {
            callback(saveNo, false);
            console.error(err.response);
            notify('Failed to save data', 'warning', 5000);
        })
    }
    return (
        <Creator.SurveyCreatorComponent creator={creator}/>
    )
}