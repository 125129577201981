import React from 'react';
import ReactDOM from 'react-dom';
import "@fontsource/open-sans";
import "./themes/dx.material.idc/dx.material.idc.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/AuthCfg";
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
      <MsalProvider instance={msalInstance}>
          <BrowserRouter>
          <App />
      </BrowserRouter>
      </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
