import React, {useState} from "react";

function getErrorHeader(status_code) {
    switch (status_code) {
        case 200:
            return "Hold on!";
        case 401:
            return "Authentication required for this resource"
        case 403:
            return "Access denied for this resource"
        case 404:
            return "The resource has not been found"
        default:
            return "Sorry, an issue occurred while processing your request";
    }
}

export default function ErrorMessage(props) {
    // Currently used in UserSurvey for showing somewhat formatted error screen.
    const [isVisible, setIsVisible] = useState(true);
    if (props.status === 401 && props.redirect) {
        // 401: Unauthorized => Show login button
        setIsVisible(false);
        window.location = `/?redirect=${props.redirect}`
    }
    if (!isVisible)
        return null
    return (
        <div className={props.hideBackground ? 'text-left' : 'float-paper-el text-left'}>
            <h4>{getErrorHeader(props.status)}</h4>
            <hr/>
            <p>{props.msg}</p>
        </div>
    )
}