import React from "react";
import Nav from 'react-bootstrap/Nav'
import {LinkContainer} from "react-router-bootstrap";

export function AdminLinks() {
    return (
        <Nav className={"me-auto"} defaultActiveKey="/">
            <LinkContainer to="/admin/survey">
                <Nav.Link>Surveys</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/profile">
                <Nav.Link>My Profile</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/help">
                <Nav.Link>Help</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/about">
                <Nav.Link>About</Nav.Link>
            </LinkContainer>
            {/*<LinkContainer to="/admin/user">*/}
            {/*    <Nav.Link>Manage Users</Nav.Link>*/}
            {/*</LinkContainer>*/}
        </Nav>
    )
}

export function UserLinks() {
    return (
        <Nav className={"me-auto"} defaultActiveKey="/">
            {/*<LinkContainer to="/">*/}
            {/*    <Nav.Link>Home</Nav.Link>*/}
            {/*</LinkContainer>*/}
            <LinkContainer to="/about">
                <Nav.Link>About</Nav.Link>
            </LinkContainer>
        </Nav>
    )
}