import React, {useState} from "react";
import Stack from "react-bootstrap/Stack";
import {SelectBox} from "devextreme-react/select-box";
import {SURVEY_HISTORY_STORE} from "../DataSources";
import SurveyViewer from "./SurveyViewer";
import {useAuthToAPI} from "../../common/Utilities";

export default function ViewSurvey(props) {
    const [versionData, setVersionData] = useState([]);
    const [surveyContent, setSurveyContent] = useState(props.data);
    const [selectedVersion, setSelectedVersion] = useState(0);
    useAuthToAPI(() => {
        SURVEY_HISTORY_STORE(props.id).load().then((res) => {
            let data = res;
            data.push({
                id: 0,
                survey: props.id,
                version: 'Current',
                friendly_name: 'Latest Version',
            });
            setVersionData(data);
        });
        setSelectedVersion(0);
        setSurveyContent(props.data);
    }, [props.data]); // Set props.data as a dependency, so we can auto-update on content change.
    return (<>
        <Stack>
            <SelectBox dataSource={versionData}
                       placeholder={"Select a survey version..."}
                       valueExpr={"id"}
                       displayExpr={"friendly_name"}
                       value={selectedVersion}
                       onValueChanged={(e) => {
                           setSelectedVersion(e.value);
                           if (e.value === 0)
                               setSurveyContent(props.data);
                           else
                               setSurveyContent(versionData.find(x => x.id === e.value).content);
                       }}/>
            <SurveyViewer data={surveyContent}/>
        </Stack>
    </>)
}