import React from "react";
import {AdminHeader} from "../common/Headers";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";

export default function Help() {
    return (<>
            <AdminHeader/>
            <Container className={'float-paper-el'}>
                <Row>
                    <h4>Frequently Asked Questions</h4>
                    <hr/>
                    <Accordion>
                        <Accordion.Item eventKey={'0'}>
                            <Accordion.Header>How do I create a survey?</Accordion.Header>
                            <Accordion.Body>Access the Survey section by clicking in the "Survey" option in the menu
                                bar, then click the "<span className="dx-icon-add"/> New Survey"
                                button.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'1'}>
                            <Accordion.Header>What are the fields used to create/edit surveys?</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><b>Internal Name:</b> An identification for the survey within AnswerMe.
                                        Respondents are
                                        unable to see it.
                                    </li>
                                    <li><b>Survey URL:</b> An identification used in the share link. If you type in
                                        <i>"mycoolsurvey"</i>, then the share link will be
                                        <i>"https://answerme.idc.com/survey/mycoolsurvey"</i>. Leave it blank for a
                                        randomly
                                        generated link.
                                    </li>
                                    <li><b>Type:</b> Controls access to the survey. "Public" can be shared freely while
                                        "Internal (IDC)" is only accessible by IDC users logged in AnswerMe.
                                    </li>
                                    <li><b>Notes:</b> Any specific detail you want to add to the survey. Respondents are
                                        unable
                                        to see it.
                                    </li>
                                    <li><b>Allow user to redo the survey:</b> Only appears for "Internal (IDC)" survey
                                        types.
                                        Allows the user to answer the survey again.
                                    </li>
                                    <li><b>Status:</b> Only appears for created surveys. "Draft" is the initial status,
                                        where you are allowed to edit the survey content. "In Progress" allows access to
                                        respondents, but you are unable to edit the content anymore. "Completed" blocks
                                        access to respondents again and shows a final message.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'2'}>
                            <Accordion.Header>Where can I edit the survey after closing it?</Accordion.Header>
                            <Accordion.Body>
                                On the "Survey" menu, click on the blue ID number to access the survey options, where
                                you can edit, test, share or view metrics.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'3'}>
                            <Accordion.Header>How can I add questions to a survey?</Accordion.Header>
                            <Accordion.Body>
                                Open a survey,press the "<span className="dx-icon-edit"/> Edit" button to
                                unlock the "Edit Questionnaire" tab. There, you'll be able to add, edit, or remove
                                questions as you please, furthermore you are able to add custom logic by clicking on the
                                question itself and editing its options.
                                <br/>
                                For more information about how to manipulate questions, see <a
                                href={'https://surveyjs.io/Documentation/Survey-Creator?id=End-User-Guide'}>here</a>.
                                <br/>
                                For real-world examples, see <a
                                href={'https://surveyjs.io/Examples/Survey-Creator'}>here</a>.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'4'}>
                            <Accordion.Header>How can I test my survey?</Accordion.Header>
                            <Accordion.Body>
                                Open a survey and click on the "Questionnaire" tab, it'll launch your survey while not
                                saving any data or
                                adding to the metrics.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'5'}>
                            <Accordion.Header>Where can I see the metrics? Can I export them?</Accordion.Header>
                            <Accordion.Body>Open a survey and click on the "Results" tab. You'll see a
                                general overview (Page Views and Completed results) alongside reports. You are
                                able to view reports by user responses, and export them into an Excel
                                file by clicking on the "<span className="dx-icon-xlsxfile"/> Export"
                                button.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'6'}>
                            <Accordion.Header>Can I let others read and edit my survey?</Accordion.Header>
                            <Accordion.Body>Yes, by opening a survey and clicking on the "Share with..." tab. Currently,
                                you can share with AnswerMe users and groups or invite IDC users to the system. You can
                                also allow the user to receive the same notifications as you by setting "Receive email
                                notifications" to Yes.
                                <br/>
                                Be aware that only the owner of the user is able to share to users and
                                groups.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'7'}>
                            <Accordion.Header>Can I keep track of changes made to my survey?</Accordion.Header>
                            <Accordion.Body>Yes, by opening a survey and clicking on the "<span
                                className="dx-icon-detailslayout"/> Logs" button. All changes to the survey (creation,
                                updates, deletion) are shown in the Log section.
                                <br/>
                                Furthermore, any changes to the survey content after it has been set to "In Progress"
                                once can be seen under "Questionnaire" by using the version selector.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'8'}>
                            <Accordion.Header>Will I lose my current answer history if I make changes to the
                                survey?</Accordion.Header>
                            <Accordion.Body>No, you can find previous answers by changing the selected version under
                                "Reports" in the "Results" tab.
                                <br/>
                                The answers will be shown as exactly as they were before the changes were
                                made.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'9'}>
                            <Accordion.Header>Can I clone/duplicate my current survey?</Accordion.Header>
                            <Accordion.Body>Yes, by opening a survey and clicking on the "<span
                                className="dx-icon-repeat"/> Clone" button under "Survey Details".
                                <br/>
                                All data is copied over, except for answers, metrics and versions. Furthermore, a new
                                URL is generated.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'10'}>
                            <Accordion.Header>Can I disable email notifications?</Accordion.Header>
                            <Accordion.Body>Yes, if you're the survey's owner, you can disable your notifications by
                                setting "Send an email notification whenever the survey is completed" to "No" under
                                "Survey
                                Details". If the survey was shared with you instead, please ask the survey's owner to
                                change "Receive email notifications" to "No" under "Share with..." or under group
                                management instead.
                                <br/>
                                Finally, it is also possible to disable owner's notifications while keeping it active
                                per shared user.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
            </Container>
        </>)
}