import React, {useRef} from "react";
import {PERMISSION_STORE, AZURE_STORE, SURVEY_SHARE_STORE, BOOLEAN_STORE} from "../../DataSources";
import {DataGrid, Column, Lookup} from "devextreme-react/data-grid";
import AzureUserSearch from "./AzureUserSearch";

export default function ShareUsers(props) {
    const gridRef = useRef(null);
    return (
        <>
            <DataGrid
                dataSource={SURVEY_SHARE_STORE(props.id)}
                ref={gridRef}
                onInitNewRow={(e) => {
                    // Append the current survey_id.
                    e.data.survey = props.id;
                }}
                onRowValidating={(e) => {
                    // Validation is being done by hand due to custom editor.
                    let errors = "";
                    if (e.newData.user === undefined || e.newData.user === null) {
                        errors += "user is required;";
                        e.isValid = false;
                    }
                    if (e.newData.permission === undefined || e.newData.permission === null) {
                        errors += "permission is required;";
                        e.isValid = false;
                    }
                    if (e.newData.is_notify === undefined || e.newData.is_notify === null) {
                        errors += "you must set notifications;";
                        e.isValid = false;
                    }
                    if (!e.isValid) {
                        let res = errors.slice(0, -1).replaceAll(";", ", ") + ".";
                        e.errorText = res.charAt(0).toUpperCase() + res.substring(1);
                    }
                    // Input: user is required; permission is required;
                    // Output: User is required, permission is required.
                }}
                onSaved={(e) => {
                    // Refresh grid to update ID column.
                    gridRef.current.instance().refresh();
                }}
                editing={{
                    allowDeleting: true,
                    allowUpdating: false,
                    allowAdding: true,
                    mode: "form",
                }}>
                <Column dataField={'id'} visible={false} dataType={'number'} formItem={{visible: false}}/>
                <Column dataField={'survey'} visible={false} dataType={'number'} formItem={{visible: false}}/>
                <Column dataField={'user'} caption={'Name'} dataType={'string'} editCellComponent={AzureUserSearch}>
                    <Lookup dataSource={AZURE_STORE} valueExpr={'id'} displayExpr={'name'}/>
                </Column>
                <Column dataField={'permission'} caption={'Permission'} dataType={'string'}>
                    <Lookup dataSource={PERMISSION_STORE} valueExpr={'id'} displayExpr={'name'}/>
                </Column>
                <Column dataField={'is_notify'} caption={'Receive email notifications'} dataType={'boolean'}>
                    <Lookup dataSource={BOOLEAN_STORE} valueExpr={'id'} displayExpr={'name'}/>
                </Column>
            </DataGrid>
        </>
    )
}