import React, {useEffect} from "react";
import {SignInButton} from "./Buttons";
import "../common/Utilities.css";
import logo from "../common/logo.svg";

export default function LoggedOut() {
    useEffect(() => {
        document.body.className = "bg-idc";
        return () => {
            document.body.className = "bg-app";
        }
    }, [])
    return (
        <div className={"center"}>
            <div className={"flex-column"}>
                <img
                    alt="IDC"
                    src={logo}
                    height={"100"}
                    width={"332"}
                    className={"flex-margin"}
                />
                <h1 className={"white-text"}>AnswerMe</h1>
                {process.env.NODE_ENV !== 'production' && <span className={"debug-banner flex-margin"}>Development build</span>}
                <SignInButton className={"flex-margin"}/>
            </div>
        </div>
    )
}