import React, {useState, useRef} from "react";
import {Column, DataGrid, SearchPanel, Button} from "devextreme-react/data-grid";
import {GROUP_STORE} from "../DataSources";
import {Popup} from "devextreme-react/popup";
import ManageGroups from "./ManageGroups";

export default function ListGroups(props) {
    const [show, setShow] = useState(false);
    const currentId = useRef(null);
    const manageMembers = () => {
        return <ManageGroups id={currentId.current}/>
    }
    return (<>
        <Popup title={"Manage members"}
               hint={"Manage members"}
               height={"95%"}
               contentRender={manageMembers}
               onHiding={() => {
                   setShow(false)
               }}
               visible={show}
               showTitle={true}
               showCloseButton={true}
               position={"top"}/>
        <DataGrid dataSource={GROUP_STORE}
                  columnAutoWidth={true}
                  editing={{
                      allowAdding: true,
                      allowDeleting: true,
                      mode: "row",
                  }}
                  width={"100%"}>
            <SearchPanel visible={true} highlightCaseSensitive={true}/>
            <Column dataField={'id'} visible={false} dataType={'string'}/>
            <Column dataField={'name'} dataType={'string'}/>
            <Column dataField={'is_active'} visible={false} dataType={'boolean'} caption={'Enabled'}/>
            <Column type={"buttons"}>
                <Button name={"manage"} icon={"edit"} visible={(e) => {
                    return !e.row.isEditing
                }} hint={"Manage groups"} onClick={(e) => {
                    currentId.current = e.row.data.id;
                    setShow(true);
                }}/>
                <Button name="delete"/>
            </Column>
        </DataGrid>
    </>)
}