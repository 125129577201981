import React, {useState} from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {useParams} from "react-router-dom";
import axios from "axios";
import notify from "devextreme/ui/notify";
import LoadPanel, {Position} from "devextreme-react/load-panel";
import "../common/Utilities.css";
import {AdminHeader} from "../common/Headers";
import {useAuthToAPI, getCookie} from "../common/Utilities";
import {SURVEY_API} from "./DataSources";
import SurveyCreator from "./edit/SurveyCreator";
import SurveyShare from "./edit/SurveyShare";
import SurveyInformation from "./edit/SurveyInformation";
import ViewSurvey from "./edit/ViewSurvey";
import ViewResults from "./edit/ViewResults";

export default function SurveyEdit() {
    const id = useParams().id;
    const [key, setKey] = useState("edit");
    const [surveyData, setSurveyData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const AXIOS_HEADERS = {
        headers: {
            'X-CSRFToken': getCookie('csrftoken')
        }
    };
    useAuthToAPI(() => {
        // Load survey from URL params.
        setIsLoading(true);
        axios.get(`${SURVEY_API}${id}`).then((res) => {
            setSurveyData(res.data);
            setIsLoading(false);
        }).catch((err) => {
            if (err.response.status === 404) {
                notify('Survey not found. Redirecting...', 'error', 3000);
                setTimeout(() => {
                    window.location.replace('/admin/survey/');
                }, 3000);
            } else {
                notify(`Failed to load data - ${err.response.status}: ${err.response.statusText}`, 'warning', 10000);
                console.error(err.response);
            }
        });
    });
    if (isLoading)
        return (<>
            <AdminHeader/>
            <LoadPanel hideOnOutsideClick={false}
                       visible={isLoading}>
                <Position at={"center"}/>
            </LoadPanel>
        </>)
    return (
        <>
            <AdminHeader/>
            <Container className={'float-paper-el'}>
                <Tabs activeKey={key} onSelect={(k) => setKey(k)} className={"mb-3"}>
                    <Tab eventKey={"edit"} title={"Survey Details"}>
                        <SurveyInformation data={surveyData}
                                           isEditing={isEditing}
                                           setIsEditing={setIsEditing}
                                           setData={setSurveyData}
                                           isOwner={surveyData.access_level === 0}
                                           allowEditing={surveyData.access_level === 0 || surveyData.access_level === 2}
                                           className={"col-sm"}
                                           headers={AXIOS_HEADERS}/>
                    </Tab>
                    {isEditing &&
                        <Tab eventKey={"creator"} title={"Edit Questionnaire"}>
                            <SurveyCreator id={surveyData.id}
                                           survey={surveyData.survey}
                                           setData={setSurveyData}
                                           headers={AXIOS_HEADERS}/>
                        </Tab>}
                    <Tab eventKey={"view"} title={"Questionnaire"}>
                        <Row>
                            <ViewSurvey className={"col-sm"}
                                        id={surveyData.id}
                                        data={surveyData.survey}/>
                        </Row>
                    </Tab>
                    <Tab eventKey={"results"} title={"Results"}>
                        <Container>
                            <ViewResults setSurveyData={setSurveyData}
                                         surveyData={surveyData}
                            />
                        </Container>
                    </Tab>
                    {surveyData.access_level === 0 &&
                        <Tab eventKey={"share"} title={"Share with..."}>
                            <SurveyShare headers={AXIOS_HEADERS} setSurveyData={setSurveyData} surveyData={surveyData}/>
                        </Tab>
                    }
                </Tabs>
            </Container>
        </>
    )
}