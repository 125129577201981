import React, {useState} from "react";
import {Form, SimpleItem, GroupItem, ButtonItem} from "devextreme-react/form";
import ViewAnswers from "./results/ViewAnswers";
import ViewMetrics from "./results/ViewMetrics";
import axios from "axios";
import {ANSWER_API, SURVEY_API, SURVEY_HISTORY_STORE} from "../DataSources";
import notify from "devextreme/ui/notify";
import LoadPanel, {Position} from "devextreme-react/load-panel";
import {isEmptyObject, useAuthToAPI} from "../../common/Utilities";

export default function ViewResults(props) {
    const modes = [
        {id: 1, value: "Individual Responses"},
        // {id: 2, value: "Report by Questions and Answers"}
    ];
    const [currentMode, setCurrentMode] = useState(1);
    const [selectedVersion, setSelectedVersion] = useState(0);
    const [answerData, setAnswerData] = useState({});
    const [versionData, setVersionData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const answers = () => {
        return isEmptyObject(answerData) ?
            (<div>Reports unavailable: No answers were found for the <strong>selected</strong> version.</div>) :
            (<ViewAnswers survey_name={props.surveyData.name} columns={answerData.columns} data={answerData.results}/>);
    };
    const metrics = () => {
        return (<ViewMetrics id={props.surveyData.id}/>);
    };
    const noAnswers = () => {
        return (<div>Reports unavailable: No answers were found.</div>)
    };
    useAuthToAPI(() => {
        if (props.surveyData.completed !== 0) {
            setIsLoading(true);
            axios.get(`${ANSWER_API}${props.surveyData.id}`).then((res) => {
                setAnswerData(res.data);
            }).catch((err) => {
                setIsLoading(false);
                if (err.response.data.showNotify) {
                    notify(err.response.data.detail, 'warning', 5000);
                }
                console.error(err.response);
            });
            SURVEY_HISTORY_STORE(props.surveyData.id).load().then((res) => {
                setIsLoading(false);
                let data = res.filter(x => x.has_answers);
                data.push({
                    id: 0,
                    survey: props.id,
                    version: 'Current',
                    friendly_name: 'Latest Version',
                });
                setVersionData(data);
                setSelectedVersion(0);
            });
        }
    }, [props.surveyData.completed]); // Set props.data as a dependency, so we can auto-update on content change.
    return (
        <>
            <LoadPanel hideOnOutsideClick={false}
                       visible={isLoading}>
                <Position at={"center"}/>
            </LoadPanel>
            <Form width={"100%"}
                  formData={props.surveyData}
                  showValidationSummary={true}
                  showRequiredMark={true}
                  showOptionalMark={false}
                  scrollingEnabled={false}
            >
                <GroupItem colCount={5}>
                    <GroupItem colCount={2} colSpan={4}>
                        <SimpleItem dataField={"completed"}
                                    colSpan={1}
                                    label={{text: "Responses (Completed)"}}
                                    editorOptions={{readOnly: true}}/>
                        <SimpleItem dataField={"total_hits"}
                                    colSpan={1}
                                    label={{text: "Page Views"}}
                                    editorOptions={{readOnly: true}}/>
                    </GroupItem>
                    <ButtonItem
                        colSpan={1}
                        horizontalAlignment={'left'}
                        verticalAlignment={'bottom'}
                        buttonOptions={{
                            text: "Refresh",
                            icon: 'refresh',
                            type: 'default',
                            stylingMode: 'outlined',
                            onClick: () => {
                                // AuthToAPI should have run in the parent component (SurveyEdit)
                                setIsLoading(true);
                                // Refresh parent data for updated "Completed" and "Page Hits"
                                axios.get(`${SURVEY_API}${props.surveyData.id}`).then((res) => {
                                    props.setSurveyData(res.data);
                                }).catch((err) => {
                                    setIsLoading(false);
                                    notify('Failed to refresh General Metrics', 'warning', 5000);
                                    console.error(err.response);
                                });
                                // Answer data is refreshed on useAuthToAPI (tied to completed answers)
                                setIsLoading(false);
                            }
                        }}/>
                </GroupItem>
                <GroupItem caption={"Reports"} visible={props.surveyData.completed !== 0}>
                    <SimpleItem editorType={"dxSelectBox"}
                                label={{text: "View"}}
                                editorOptions={{
                                    dataSource: modes,
                                    valueExpr: "id",
                                    displayExpr: "value",
                                    value: currentMode,
                                    onValueChanged: (e) => {
                                        setCurrentMode(e.value);
                                    }
                                }}
                    />
                    <SimpleItem editorType={"dxSelectBox"}
                                label={{text: "Selected Version"}}
                                editorOptions={{
                                    dataSource: versionData,
                                    valueExpr: "id",
                                    displayExpr: "friendly_name",
                                    value: selectedVersion,
                                    onValueChanged: (e) => {
                                        setSelectedVersion(e.value);
                                        setIsLoading(true);
                                        let axios_conf = {};
                                        if (e.value !== 0) {
                                            axios_conf['params'] = {'version': versionData.find(x => x.id === e.value).version};
                                        }
                                        axios.get(`${ANSWER_API}${props.surveyData.id}`, axios_conf).then((res) => {
                                            setAnswerData(res.data);
                                            setIsLoading(false);
                                        }).catch((err) => {
                                            setIsLoading(false);
                                            if (err.response.data.showNotify) {
                                                notify(err.response.data.detail, 'warning', 5000);
                                            }
                                            console.error(err.response);
                                        });
                                    }
                                }}
                    />
                    <SimpleItem visible={currentMode === 1} render={answers}/>
                    <SimpleItem visible={currentMode === 2} render={metrics}/>
                </GroupItem>
                <GroupItem caption={"Reports"} visible={props.surveyData.completed === 0}>
                    <SimpleItem render={noAnswers}/>
                </GroupItem>
            </Form>
        </>
    )
}