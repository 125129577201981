import CustomStore from 'devextreme/data/custom_store';
import axios from "axios";
import {getCookie} from "../common/Utilities";

// Private API exports
export const USER_API = process.env.REACT_APP_API_URL + '/api/v1/auth/__user/';
export const PROFILE_API = process.env.REACT_APP_API_URL + '/api/v1/auth/__profile/';
export const GROUP_API = process.env.REACT_APP_API_URL + '/api/v1/auth/__group/';
export const GROUP_MEMBERS_API = process.env.REACT_APP_API_URL + '/api/v1/auth/__group/members/';
export const PERMISSION_API = process.env.REACT_APP_API_URL + '/api/v1/auth/__permission/';
export const AZURE_API = process.env.REACT_APP_API_URL + '/api/v1/auth/__azure/';
export const SURVEY_API = process.env.REACT_APP_API_URL + '/api/v1/survey/__survey/';
export const SURVEY_SHARE_API = process.env.REACT_APP_API_URL + '/api/v1/survey/__survey/shared/';
export const SURVEY_HISTORY_API = process.env.REACT_APP_API_URL + '/api/v1/survey/__survey/history/';
export const SURVEY_LOG_API = process.env.REACT_APP_API_URL + '/api/v1/survey/__survey/log/';
export const STATUS_API = process.env.REACT_APP_API_URL + '/api/v1/survey/__status/';
export const TYPE_API = process.env.REACT_APP_API_URL + '/api/v1/survey/__type/';
export const ANSWER_API = process.env.REACT_APP_API_URL + '/api/v1/survey/__answers/';
export const METRICS_API = process.env.REACT_APP_API_URL + '/api/v1/survey/__metrics/';
export const ANSWER_FILE_API = process.env.REACT_APP_API_URL + '/api/v1/survey/__file/';


const AXIOS_HEADERS = {
    headers: {
        'X-CSRFToken': getCookie('csrftoken')
    }
}

export const BOOLEAN_STORE = [
    {
        id: true,
        name: "Yes"
    },
    {
        id: false,
        name: "No"
    }
];

export const USER_STORE = new CustomStore({
    key: 'id',
    load: () => {
        return axios.get(USER_API).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err
        })
    },
    insert: (values) => {
        return axios.post(USER_API, values, AXIOS_HEADERS).then((res) => {
            return res.data;
        }).catch((err) => {
            return err
        })
    },
    update: (key, values) => {
        let url = `${USER_API}${key}/`
        return axios.patch(url, values, AXIOS_HEADERS).then((res) => {
            return res.data;
        }).catch((err) => {
            return err
        })
    },
    remove: (key) => {
        let url = `${USER_API}${key}/`
        return axios.delete(url, AXIOS_HEADERS).then((res) => {
            return res.data;
        }).catch((err) => {
            return err
        })
    },
    byKey: (key) => {
        let url = `${USER_API}${key}/`
        return axios.get(url).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err
        })
    }
});

export const PROFILE_STORE = new CustomStore({
    key: 'id',
    load: () => {
        return axios.get(PROFILE_API).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err;
        })
    },
    byKey: (key) => {
        let url = `${PROFILE_API}${key}`;
        return axios.get(url).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err;
        })
    }
})

export const SURVEY_STORE = new CustomStore({
    key: 'id',
    load: () => {
        return axios.get(SURVEY_API).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err
        })
    },
    insert: (values) => {
        return axios.post(SURVEY_API, values, AXIOS_HEADERS).then((res) => {
            return res.data;
        }).catch((err) => {
            return err
        })
    },
    update: (key, values) => {
        let url = `${SURVEY_API}${key}/`
        return axios.patch(url, values, AXIOS_HEADERS).then((res) => {
            return res.data;
        }).catch((err) => {
            return err
        })
    },
    remove: (key) => {
        let url = `${SURVEY_API}${key}/`
        return axios.delete(url, AXIOS_HEADERS).then((res) => {
            return res.data;
        }).catch((err) => {
            return err
        })
    },
    byKey: (key) => {
        let url = `${SURVEY_API}${key}/`
        return axios.get(url).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err
        })
    }
});

export const STATUS_STORE = new CustomStore({
    key: 'id',
    load: () => {
        return axios.get(STATUS_API).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err
        })
    },
    byKey: (key) => {
        let url = `${STATUS_API}${key}/`
        return axios.get(url).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err
        })
    }
});

export const TYPE_STORE = new CustomStore({
    key: 'id',
    load: () => {
        return axios.get(TYPE_API).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err
        })
    },
    byKey: (key) => {
        let url = `${TYPE_API}${key}/`
        return axios.get(url).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err
        })
    }
});

export const AZURE_STORE = new CustomStore({
    key: 'id',
    load: (options) => {
        return axios.get(AZURE_API, {
            params: options
        }).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err;
        })
    },
    byKey: (key) => {
        let url = `${AZURE_API}${encodeURIComponent(key)}`;
        return axios.get(url).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err;
        })
    }
});

export const GROUP_STORE = new CustomStore({
    key: 'id',
    load: () => {
        return axios.get(GROUP_API).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err;
        })
    },
    insert: (values) => {
        return axios.post(GROUP_API, values, AXIOS_HEADERS).then((res) => {
            return res.data;
        }).catch((err) => {
            return err
        })
    },
    update: (key, values) => {
        let url = `${GROUP_API}${key}/`
        return axios.patch(url, values, AXIOS_HEADERS).then((res) => {
            return res.data;
        }).catch((err) => {
            return err
        })
    },
    remove: (key) => {
        let url = `${SURVEY_API}${key}/`
        return axios.delete(url, AXIOS_HEADERS).then((res) => {
            return res.data;
        }).catch((err) => {
            return err
        })
    },
    byKey: (key) => {
        let url = `${GROUP_API}${key}`;
        return axios.get(url).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err;
        })
    }
})

export const PERMISSION_STORE = new CustomStore({
    key: 'id',
    load: () => {
        return axios.get(PERMISSION_API).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err;
        })
    },
    byKey: (key) => {
        let url = `${PERMISSION_API}${key}`;
        return axios.get(url).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error(err.response);
            return err;
        })
    }
})

export const SURVEY_SHARE_STORE = (survey_id = null) => {
    return new CustomStore({
        key: 'id',
        load: () => {
            if (survey_id === null)
                return axios.get(SURVEY_SHARE_API).then((res) => {
                    return res.data;
                }).catch((err) => {
                    console.error(err.response);
                    return err;
                })
            return axios.get(SURVEY_SHARE_API, {params: {survey: survey_id}}).then((res) => {
                return res.data;
            }).catch((err) => {
                console.error(err.response);
                return err;
            })
        },
        insert: (values) => {
            return axios.post(SURVEY_SHARE_API, values, AXIOS_HEADERS).then((res) => {
                return res.data;
            }).catch((err) => {
                return err
            })
        },
        update: (key, values) => {
            let url = `${SURVEY_SHARE_API}${key}/`
            return axios.patch(url, values, AXIOS_HEADERS).then((res) => {
                return res.data;
            }).catch((err) => {
                return err
            })
        },
        remove: (key) => {
            let url = `${SURVEY_SHARE_API}${key}/`
            return axios.delete(url, AXIOS_HEADERS).then((res) => {
                return res.data;
            }).catch((err) => {
                return err
            })
        },
        byKey: (key) => {
            let url = `${SURVEY_SHARE_API}${key}`;
            return axios.get(url).then((res) => {
                return res.data;
            }).catch((err) => {
                console.error(err.response);
                return err;
            })
        }
    })
}

export const SURVEY_HISTORY_STORE = (survey_id = null) => {
    return new CustomStore({
        key: 'id',
        load: () => {
            if (survey_id === null)
                return axios.get(SURVEY_HISTORY_API).then((res) => {
                    return res.data;
                }).catch((err) => {
                    console.error(err.response);
                    return err;
                })
            return axios.get(SURVEY_HISTORY_API, {params: {survey: survey_id}}).then((res) => {
                return res.data;
            }).catch((err) => {
                console.error(err.response);
                return err;
            })
        },
        byKey: (key) => {
            let url = `${SURVEY_HISTORY_API}${key}`;
            return axios.get(url).then((res) => {
                return res.data;
            }).catch((err) => {
                console.error(err.response);
                return err;
            })
        }
    })
}

export const SURVEY_LOG_STORE = (survey_id = null) => {
    return new CustomStore({
        key: 'id',
        load: () => {
            if (survey_id === null)
                return axios.get(SURVEY_LOG_API).then((res) => {
                    return res.data;
                }).catch((err) => {
                    console.error(err.response);
                    return err;
                })
            return axios.get(SURVEY_LOG_API, {params: {survey: survey_id}}).then((res) => {
                return res.data;
            }).catch((err) => {
                console.error(err.response);
                return err;
            })
        },
        byKey: (key) => {
            let url = `${SURVEY_LOG_API}${key}`;
            return axios.get(url).then((res) => {
                return res.data;
            }).catch((err) => {
                console.error(err.response);
                return err;
            })
        }
    })
}

export const GROUP_MEMBERS_STORE = (group_id = null) => {
    return new CustomStore({
        key: 'id',
        load: () => {
            if (group_id === null)
                return axios.get(GROUP_MEMBERS_API).then((res) => {
                    return res.data;
                }).catch((err) => {
                    console.error(err.response);
                    return err;
                })
            return axios.get(GROUP_MEMBERS_API, {params: {group: group_id}}).then((res) => {
                return res.data;
            }).catch((err) => {
                console.error(err.response);
                return err;
            })
        },
        insert: (values) => {
            return axios.post(GROUP_MEMBERS_API, values, AXIOS_HEADERS).then((res) => {
                return res.data;
            }).catch((err) => {
                return err
            })
        },
        update: (key, values) => {
            let url = `${GROUP_MEMBERS_API}${key}/`
            return axios.patch(url, values, AXIOS_HEADERS).then((res) => {
                return res.data;
            }).catch((err) => {
                return err
            })
        },
        remove: (key) => {
            let url = `${GROUP_MEMBERS_API}${key}/`
            return axios.delete(url, AXIOS_HEADERS).then((res) => {
                return res.data;
            }).catch((err) => {
                return err
            })
        },
        byKey: (key) => {
            let url = `${GROUP_MEMBERS_API}${key}`;
            return axios.get(url).then((res) => {
                return res.data;
            }).catch((err) => {
                console.error(err.response);
                return err;
            })
        }
    })
}

export const SURVEY_GROUP_STORE = (survey_id) => {
    let url = `${SURVEY_API}${survey_id}/`
    return {
        load: () => {
            return axios.get(`${url}get_available_groups/`).then((res) => {
                return res.data;
            }).catch((err) => {
                console.error(err.response);
                return err;
            })
        },
        insert: (values) => {
            return axios.post(`${url}set_group/`, values, AXIOS_HEADERS).then((res) => {
                return res.data;
            }).catch((err) => {
                return err
            })
        },
        remove: (key) => {
            let config = {
                headers: {
                    'X-CSRFToken': getCookie('csrftoken')
                },
                data: {
                    'remove': key
                }
            }
            return axios.delete(`${url}remove_group/`, config).then((res) => {
                return res.data;
            }).catch((err) => {
                return err
            })
        }
    }
};