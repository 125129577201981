import React, {useRef} from "react";
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';
import CustomStore from "devextreme/data/custom_store";
import {Column, DataGrid, SearchPanel, Grouping, Export, Item, Toolbar} from "devextreme-react/data-grid";
import {exportDataGrid} from 'devextreme/excel_exporter';
import {METRICS_API} from "../../DataSources";
import axios from "axios";

export default function ViewAnswers(props) {
    const gridRef = useRef(null);
    return (
        <>
            <DataGrid dataSource={new CustomStore({
                key: ['question'],
                load: () => {
                    return axios.get(`${METRICS_API}${props.id}/`).then((res) => {
                        return res.data;
                    }).catch((err) => {
                        console.error(err.response);
                        return err
                    })
                },
            })}
                      ref={gridRef}
                      columnAutoWidth={true}
                      width={"100%"}
                      onExporting={(e) => {
                          const workbook = new Workbook();
                          const worksheet = workbook.addWorksheet("Main sheet");
                          exportDataGrid({
                              worksheet: worksheet,
                              component: e.component
                          }).then(function () {
                              workbook.xlsx.writeBuffer().then(function (buffer) {
                                  saveAs(new Blob([buffer], {type: "application/octet-stream"}), "AnswerMe Report - Metrics Per Answer.xlsx");
                              });
                          });
                          e.cancel = true;
                      }}
            >
                <Toolbar>
                    <Item name={"exportButton"}
                          showText={"always"}
                          widget={'dxButton'}
                          options={{
                              name: "exportButton",
                              text: "Export",
                              icon: 'exportxlsx',
                              type: 'default',
                              stylingMode: 'outlined',
                          }}/>
                    <Item name={"searchPanel"}/>
                </Toolbar>
                <Export enabled={true}/>
                <SearchPanel visible={true} highlightCaseSensitive={true}/>
                <Grouping contextMenuEnabled={true}/>
                <Column dataField={'question'} caption={'Question'}/>
                <Column dataField={'answer'} caption={'Answer'}/>
                <Column dataField={'count'} caption={'Total'}/>
            </DataGrid>
        </>
    )
}
