import React, {useEffect, useRef, useState} from "react";
import {faQrcode} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form, SimpleItem, ButtonItem, GroupItem, EmptyItem, CustomRule, AsyncRule} from 'devextreme-react/form';
import DropDownButton, {Item} from 'devextreme-react/drop-down-button';
import {Popup} from "devextreme-react/popup";
import {List} from "devextreme-react/list";
import {TextArea} from "devextreme-react/text-area"; // eslint-disable-line no-unused-vars
import notify from "devextreme/ui/notify";
import axios from "axios";
import {SURVEY_API, STATUS_STORE, TYPE_STORE, BOOLEAN_STORE, SURVEY_LOG_STORE} from "../DataSources";
import QRCodeBuilder from "../../common/QRCodeBuilder";
import {isEmptyObject} from "../../common/Utilities";


const ADDITIONAL_ACTIONS = [
    {
        id: null,
        text: "Unset current action"
    },
    {
        id: 'send_completed_email',
        text: "Send an email to the user on complete",
    }
];

function parseLocalizedField(field) {
    if (Object.keys(field).includes('default'))
        return field.default // Default string
    return Object.values(field)[0] // First localized string in object
}

function getSurveyFields(pages, is_registered) {
    let fields = is_registered ? [{id: '__registered_user', text: "Use registered user's email [IDC]"}] : [];
    if (pages !== undefined && pages.length !== 0)
        pages.forEach((page) => {
            if (page.hasOwnProperty('elements')) {
                let page_fields = page.elements.filter(({type}) => type === 'text');
                if (page_fields.length !== 0) {
                    page_fields = page_fields.map(({name, title}) => ({
                        id: name,
                        text: typeof title === 'object' ? parseLocalizedField(title) : title
                    }))
                    fields = fields.concat(page_fields);
                }
            }
        });
    return fields
}

export default function SurveyInformation(props) {
    const surveyForm = useRef(null);
    const [showDel, setShowDel] = useState(false);
    const [showClone, setShowClone] = useState(false);
    const [showQr, setShowQr] = useState(false);
    const [showLog, setShowLog] = useState(false);
    const [surveyData, setSurveyData] = useState({});
    const [pageData, setPageData] = useState([]);
    const [isRegisteredSurvey, setIsRegisteredSurvey] = useState(false)
    const [action, setAction] = useState(null);
    const deleteData = () => {
        return (
            <Form width={"100%"}
                  labelMode={'hidden'}
                  showValidationSummary={true}
                  showRequiredMark={true}
                  showOptionalMark={false}
                  scrollingEnabled={false}
            >
                <GroupItem colCount={2}>
                    <ButtonItem buttonOptions={{
                        icon: 'close',
                        text: 'Cancel',
                        width: '100%',
                        onClick: () => {
                            setShowDel(false);
                        }
                    }}/>
                    <ButtonItem buttonOptions={{
                        icon: 'trash',
                        text: 'delete',
                        width: '100%',
                        type: 'danger',
                        onClick: () => {
                            props.setIsEditing(true);
                            axios.delete(`${SURVEY_API}${surveyData.id}/`, props.headers).then((res) => {
                                window.location.replace('/admin/survey')
                            }).catch((err) => {
                                console.error(err.response)
                                notify('Failed to erase data. Please contact the IT Team', 'error', 5000)
                            });
                        }
                    }}/>
                </GroupItem>
            </Form>)
    };
    const cloneData = () => {
        return (
            <Form width={"100%"}
                  labelMode={'hidden'}
                  showValidationSummary={true}
                  showRequiredMark={true}
                  showOptionalMark={false}
                  scrollingEnabled={false}
            >
                <GroupItem colCount={2}>
                    <ButtonItem buttonOptions={{
                        icon: 'close',
                        text: 'Cancel',
                        width: '100%',
                        onClick: () => {
                            setShowClone(false);
                        }
                    }}/>
                    <ButtonItem buttonOptions={{
                        icon: 'repeat',
                        text: 'Clone',
                        width: '100%',
                        type: 'success',
                        onClick: () => {
                            props.setIsEditing(true);
                            axios.post(`${SURVEY_API}${surveyData.id}/clone/`, {}, props.headers).then((res) => {
                                let url = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))
                                window.location.replace(`${url}/${res.data.id}`);
                            }).catch((err) => {
                                console.error(err.response)
                                notify('Failed to clone data. Please contact the IT Team', 'error', 5000);
                            })
                        }
                    }}/>
                </GroupItem>
            </Form>)
    };
    const qrCodeData = () => {
        return (
            <QRCodeBuilder filename={`[${props.data.id}] ${props.data.name}`}
                           value={`${window.location.origin}/survey/${props.data.url}`}/>
        )
    };
    const logData = () => {
        return (<List dataSource={SURVEY_LOG_STORE(props.data.id)}
                      displayExpr={"friendly_log"}
                      selectionMode={"none"}
                      showScrollbar={"always"}/>)
    };
    const dropDownBtnRender = () => {
        const btnItemClick = (e) => {
            switch (e.itemData.action) {
                case 'copy':
                    navigator.clipboard.writeText(`${window.location.origin}/survey/${props.data.url}`).then(() => {
                        notify('Copied to clipboard!', 'info', 3000);
                    });
                    break;
                case 'open':
                    if (window.location.href.charAt(window.location.href.length - 1) !== '/')
                        window.open(`/survey/${props.data.url}`, '_blank');
                    else
                        window.open(`/survey${props.data.url}`, '_blank');
                    break;
                case 'qrcode':
                    setShowQr(true);
                    break;
                default:
                    notify('Unimplemented command', 'warning', 5000);
                    break;
            }
        };
        const qrCodeBtn = () => {
            return (<>
                <div className={'dx-list-item-icon-container'}>
                    <FontAwesomeIcon className={'dx-icon'} width={'24px'} height={'24px'} icon={faQrcode}/>
                </div>
                QR Code
            </>)
        }
        return (
            <DropDownButton
                splitButton={false}
                useSelectMode={false}
                showArrowIcon={false}
                height={"100%"}
                width={"100%"}
                text='URL Options'
                icon='overflow'
                keyExpr={'action'}
                displayExpr={'text'}
                onItemClick={btnItemClick}>
                <Item action={'copy'} icon={'copy'} text={'Copy Link'}/>
                <Item action={'open'} icon={'link'} text={'Open Link'}/>
                <Item action={'qrcode'} render={qrCodeBtn}/>
            </DropDownButton>
        )
    };
    useEffect(() => {
        setSurveyData(Object.assign({}, props.data));
        setIsRegisteredSurvey(!isEmptyObject(props.data) && props.data.type !== 1);
        setAction(props.data.additional_actions === null ? null : props.data.additional_actions.action);
        setPageData(getSurveyFields(isEmptyObject(props.data) || isEmptyObject(props.data.survey) ? [] : props.data.survey.pages, isRegisteredSurvey));
    }, [props.data])
    return (
        <>
            <Popup title={"Are you sure you want to delete this survey?"}
                   hint={"Delete data"}
                   contentRender={deleteData}
                   onHiding={() => {
                       setShowDel(false)
                   }}
                   visible={showDel}
                   showTitle={true}
                   showCloseButton={false}
                   width={"500px"}
                   height={"130px"}
                   position={"center"}/>
            <Popup title={"Are you sure you want to clone this survey?"}
                   hint={"Delete data"}
                   contentRender={cloneData}
                   onHiding={() => {
                       setShowClone(false);
                   }}
                   visible={showClone}
                   showTitle={true}
                   showCloseButton={false}
                   width={"500px"}
                   height={"130px"}
                   position={"center"}/>
            <Popup title={"QR Code"}
                   hint={"QR Code"}
                   contentRender={qrCodeData}
                   onHiding={() => {
                       setShowQr(false)
                   }}
                   visible={showQr}
                   showTitle={true}
                   showCloseButton={true}
                   width={"400px"}
                   height={"400px"}
                   position={"center"}/>
            <Popup title={"Logs"}
                   hint={"Logs"}
                   contentRender={logData}
                   onHiding={() => {
                       setShowLog(false)
                   }}
                   visible={showLog}
                   showTitle={true}
                   showCloseButton={true}
                   width={"80%"}
                   height={"80%"}
                   position={"center"}/>
            <Form width={"100%"}
                  ref={surveyForm}
                  formData={surveyData}
                  showValidationSummary={true}
                  showRequiredMark={true}
                  showOptionalMark={false}
                  scrollingEnabled={false}
                  onFieldDataChanged={(e) => {
                      if (e.dataField === 'type' && e.value !== 1 && !isRegisteredSurvey)
                          setIsRegisteredSurvey(true);
                      else if (e.dataField === 'type' && e.value === 1 && isRegisteredSurvey)
                          setIsRegisteredSurvey(false);
                      if (e.dataField === 'additional_actions.action' && e.value !== action)
                          setAction(e.value);
                  }}>
                <GroupItem colCount={2} caption={"Survey"}>
                    <SimpleItem dataField={'name'}
                                label={{text: 'Internal Name'}}
                                isRequired={true}
                                editorOptions={{
                                    maxLength: 255,
                                    readOnly: !props.isEditing
                                }}/>
                    <GroupItem colCount={3}>
                        <SimpleItem dataField={'url'}
                                    colSpan={surveyData.id ? 2 : 3}
                                    label={{text: 'Survey URL'}}
                                    editorOptions={{
                                        maxLength: 18,
                                        placeholder: 'Leave empty for random URL',
                                        readOnly: props.data.status !== 1 || !props.isEditing,
                                    }}>
                            <CustomRule ignoreEmptyValue={true}
                                        message={'Use only letters, numbers, underscores and hyphens.'}
                                        validationCallback={(e) => {
                                            return /^[a-zA-Z0-9\-\_]+$/g.test(e.value); // PatternRule is not working properly
                                        }}/>
                            <AsyncRule ignoreEmptyValue={true}
                                       message={'Non-Unique: URL was used previously.'}
                                       validationCallback={(e) => {
                                           return new Promise((resolve, reject) => {
                                               axios.get(`${SURVEY_API}is_unique_url`, {
                                                   'params': {
                                                       'url': e.value,
                                                       'original': props.data.url
                                                   }
                                               }).then((res) => {
                                                   resolve(res.data);
                                               }).catch((err) => {
                                                   console.error(err.response);
                                                   reject("Error during URL validation check");
                                               });
                                           })
                                       }}/>
                        </SimpleItem>
                        <GroupItem colCount={1} visible={surveyData.id !== undefined}>
                            <EmptyItem colSpan={1}/>
                            <SimpleItem colSpan={1} render={dropDownBtnRender}/>
                        </GroupItem>
                    </GroupItem>
                    <SimpleItem dataField={'type'}
                                editorType={'dxSelectBox'}
                                editorOptions={{
                                    dataSource: TYPE_STORE,
                                    valueExpr: 'id',
                                    displayExpr: 'name',
                                    readOnly: !props.isEditing
                                }}
                                isRequired={true}/>
                    <SimpleItem dataField={'status'}
                                editorType={'dxSelectBox'}
                                editorOptions={{
                                    dataSource: STATUS_STORE,
                                    valueExpr: 'id',
                                    displayExpr: 'name',
                                    readOnly: !props.isEditing
                                }}
                                isRequired={true}
                                visible={surveyData.id !== undefined}/>
                    <SimpleItem dataField={'notes'}
                                colSpan={2}
                                editorType={surveyData.id !== undefined ? 'dxTextArea' : 'dxTextBox'}
                                label={{text: 'Notes'}}
                                editorOptions={{
                                    maxLength: 255,
                                    readOnly: !props.isEditing
                                }}/>
                </GroupItem>
                <GroupItem colCount={2} caption={"Additional actions"} visible={surveyData.id !== undefined}>
                    <SimpleItem dataField={'is_user_updatable'}
                                editorType={'dxSelectBox'}
                                colSpan={1}
                                label={{
                                    text: 'Allow user to redo the survey',
                                    location: 'top'
                                }}
                                editorOptions={{
                                    dataSource: BOOLEAN_STORE,
                                    valueExpr: 'id',
                                    displayExpr: 'name',
                                    readOnly: !props.isEditing
                                }}
                                visible={isRegisteredSurvey}
                    />
                    <SimpleItem dataField={'is_notify'}
                                editorType={'dxSelectBox'}
                                colSpan={1}
                                label={{
                                    text: 'Send an email notification whenever the survey is completed',
                                    location: 'top'
                                }}
                                editorOptions={{
                                    dataSource: BOOLEAN_STORE,
                                    valueExpr: 'id',
                                    displayExpr: 'name',
                                    readOnly: !props.isEditing
                                }}
                                visible={props.isOwner}
                    />
                    <SimpleItem dataField={'additional_actions.action'}
                                editorType={'dxSelectBox'}
                                colSpan={2}
                                label={{
                                    text: 'Action',
                                    location: 'top'
                                }}
                                editorOptions={{
                                    readOnly: !props.isEditing,
                                    dataSource: ADDITIONAL_ACTIONS,
                                    valueExpr: 'id',
                                    displayExpr: 'text'
                                }}>
                    </SimpleItem>
                    <GroupItem colCount={2} colSpan={2} visible={action === 'send_completed_email'}>
                        <SimpleItem dataField={'additional_actions.config.send_to_field'}
                                    editorType={'dxSelectBox'}
                                    colSpan={2}
                                    label={{
                                        text: 'Get email address from field',
                                        location: 'top'
                                    }}
                                    editorOptions={{
                                        dataSource: pageData,
                                        readOnly: !props.isEditing,
                                        valueExpr: 'id',
                                        displayExpr: 'text'
                                    }}
                        />
                        <SimpleItem dataField={'additional_actions.config.is_notify_bcc'}
                                    editorType={'dxSelectBox'}
                                    colSpan={2}
                                    label={{
                                        text: 'Receive a copy of the email sent',
                                        location: 'top'
                                    }}
                                    editorOptions={{
                                        dataSource: BOOLEAN_STORE,
                                        valueExpr: 'id',
                                        displayExpr: 'name',
                                        readOnly: !props.isEditing
                                    }}
                        />
                        <SimpleItem dataField={'additional_actions.config.subject'}
                                    editorType={'dxTextBox'}
                                    colSpan={2}
                                    label={{
                                        text: 'Subject',
                                        location: 'top'
                                    }}
                                    editorOptions={{
                                        readOnly: !props.isEditing
                                    }}
                        />
                        <SimpleItem dataField={'additional_actions.config.body'}
                                    editorType={'dxTextArea'}
                                    colSpan={2}
                                    label={{
                                        text: 'Body',
                                        location: 'top'
                                    }}
                                    editorOptions={{
                                        readOnly: !props.isEditing
                                    }}
                        />
                    </GroupItem>
                </GroupItem>
                <GroupItem colCount={12}>
                    <EmptyItem colSpan={3} visible={props.isEditing}/>
                    <GroupItem colSpan={12} colCount={5} visible={!props.isEditing}>
                        <ButtonItem horizontalAlignment={'center'}
                                    buttonOptions={{
                                        icon: 'back',
                                        text: 'Back',
                                        // width: '100%',
                                        type: 'normal',
                                        stylingMode: 'outlined',
                                        onClick: () => {
                                            window.location.replace('/admin/survey');
                                        }
                                    }}/>
                        <ButtonItem horizontalAlignment={'center'}
                                    buttonOptions={{
                                        icon: 'repeat',
                                        text: 'Clone',
                                        type: 'default',
                                        stylingMode: 'outlined',
                                        onClick: (c) => {
                                            setShowClone(true);
                                        }
                                    }}/>
                        <ButtonItem horizontalAlignment={'center'}
                                    buttonOptions={{
                                        icon: 'trash',
                                        text: 'Delete',
                                        disabled: !props.allowEditing,
                                        // width: '100%',
                                        type: 'danger',
                                        stylingMode: 'outlined',
                                        onClick: (c) => {
                                            setShowDel(true);
                                        }
                                    }}/>
                        <ButtonItem horizontalAlignment={'center'}
                                    buttonOptions={{
                                        icon: 'edit',
                                        text: 'Edit',
                                        disabled: !props.allowEditing,
                                        // width: '100%',
                                        type: 'success',
                                        stylingMode: 'outlined',
                                        onClick: () => {
                                            props.setIsEditing(true);
                                        }
                                    }}/>
                        <ButtonItem horizontalAlignment={'center'}
                                    buttonOptions={{
                                        icon: 'detailslayout',
                                        text: 'Logs',
                                        disabled: !props.allowEditing,
                                        // width: '100%',
                                        type: 'default',
                                        stylingMode: 'outlined',
                                        onClick: () => {
                                            setShowLog(true);
                                        }
                                    }}/>
                    </GroupItem>
                    <GroupItem colSpan={6} colCount={2} visible={props.isEditing}>
                        <ButtonItem
                            horizontalAlignment={'left'}
                            buttonOptions={{
                                icon: 'close',
                                text: 'cancel',
                                type: 'default',
                                stylingMode: 'outlined',
                                onClick: () => {
                                    if (surveyData.id !== undefined) {
                                        setSurveyData(props.data);
                                        setIsRegisteredSurvey(!isEmptyObject(props.data) && props.data.type !== 1);
                                        setAction(props.data.additional_actions === null ? null : props.data.additional_actions.action);
                                        setPageData(getSurveyFields(isEmptyObject(props.data) || isEmptyObject(props.data.survey) ? [] : props.data.survey.pages, isRegisteredSurvey));
                                        props.setIsEditing(false);
                                    } else {
                                        props.setShow(false);
                                    }
                                }
                            }}/>
                        <ButtonItem horizontalAlignment={'right'}
                                    colSpan={surveyData.id ? 1 : 2}
                                    buttonOptions={{
                                        icon: 'save',
                                        text: 'Save',
                                        type: 'success',
                                        stylingMode: 'outlined',
                                        onClick: (c) => {
                                            const validateData = (r) => {
                                                c.component.option('disabled', true);
                                                switch (r.status) {
                                                    case 'valid':
                                                        if (surveyData.id === undefined) {
                                                            axios.post(SURVEY_API, surveyData, props.headers).then((res) => {
                                                                window.location.replace(`${window.location.pathname}/${res.data.id}`);
                                                            }).catch((err) => {
                                                                console.error(err.response)
                                                                notify('Failed to save data. Please contact the IT Team', 'error', 5000);
                                                            })
                                                        } else {
                                                            // Trigger URL generation in backend.
                                                            surveyData['url'] = [null, undefined, ""].includes(surveyData['url']) ? '___generate1url___' : surveyData['url'];
                                                            axios.patch(`${SURVEY_API}${surveyData.id}/`, surveyData, props.headers).then((res) => {
                                                                props.setData(res.data);
                                                                notify('Changes were saved successfully.', 'success', 5000);
                                                                c.component.option('disabled', false);
                                                                props.setIsEditing(false);
                                                            }).catch((err) => {
                                                                console.error(err.response)
                                                                notify('Failed to save data. Please contact the IT Team', 'error', 5000);
                                                            })
                                                        }
                                                        break;
                                                    case 'pending':
                                                        // It should never be hit, given the promise must be solved.
                                                        result.complete.then((r) => {
                                                            validateData(r);
                                                        });
                                                        // c.component.option('disabled', false);
                                                        // notify('URL validation failed. If this persists, please contact the IT Team', 'error', 5000);
                                                        break;
                                                    case 'invalid':
                                                        c.component.option('disabled', false);
                                                        notify('Inputted data is invalid. Please verify your fields', 'error', 5000);
                                                        break;
                                                    default:
                                                        // Fallback for new, unknown state implemented by DevExpress.
                                                        c.component.option('disabled', false);
                                                        console.log(`New state: ${r.status}`)
                                                        notify('Unable to save data. Contact the IT Team', 'error', 5000);
                                                        break;
                                                }
                                            }
                                            let result = surveyForm.current.instance().validate();
                                            validateData(result);
                                        }
                                    }}/>
                    </GroupItem>
                    <EmptyItem colSpan={3} visible={props.isEditing}/>
                </GroupItem>
            </Form>
        </>
    )
}