import React, {useEffect} from "react";
import "../common/Utilities.css";
import logo from "../common/logo.svg";
import {useMsal} from "@azure/msal-react";
import {logoutRequest} from "./AuthCfg";
import axios from "axios";
import {delCookie, getCookie} from "../common/Utilities";

export default function SignOut() {
    const {instance, accounts, inProgress} = useMsal();
    useEffect(() => {
        const API_LOGOUT_URL = process.env.REACT_APP_API_URL + "/api/v1/auth/logout/";
        const requestLogout = {
            ...logoutRequest,
            account: accounts[0]
        };
        axios.post(API_LOGOUT_URL, {}, {
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        }).then((res) => {
            delCookie('csrftoken'); // Expire Django session cookie
            instance.logoutRedirect(requestLogout);
        }).catch((err) => {
            delCookie('csrftoken'); // Expire Django session cookie
            instance.logoutRedirect(requestLogout);
        });
        document.body.className = "bg-idc";
        return () => {
            document.body.className = null;
        }
    }, [instance])
    return (
        <div className={"center"}>
            <div className={"flex-column"}>
                <img
                    alt="IDC"
                    src={logo}
                    height={"100"}
                    width={"332"}
                    className={"flex-margin"}
                />
                <h3 className={"white-text flex-margin"}>You're being signed off.</h3>
            </div>
        </div>
    )
}