import React from "react";
import {UserHeader} from "../common/Headers";
import {useAuthToAPI} from "../common/Utilities";

export default function LoggedIn(props) {
    useAuthToAPI(() => {
        if (props.redirect)
            window.location.replace(props.redirect);
        else
            window.location.replace('/admin/survey');
    });
    return (
        <>
            <UserHeader/>
        </>
    )
}