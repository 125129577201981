import React, {useState} from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import notify from "devextreme/ui/notify";
import {getSurveyLocales} from "../../common/Utilities";
import SelectBox from "devextreme-react/select-box";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import showdown from "showdown/dist/showdown";

export default function SurveyViewer(props) {
    Survey.StylesManager.applyTheme('default');
    const model = new Survey.Model(props.data);
    const [locale, setLocale] = useState(model.getLocale());
    const localeData = getSurveyLocales(model.getUsedLocales(), model.getLocale());
    model.onComplete.add((survey) => {
        notify('Survey completed.', 'success', 5000)
    })
    model.onTextMarkdown.add((survey, options) => {
        options.html = options.text;
    });
    model.locale = locale;
    return (
        <>
            <Container>
                <Row>
                    <SelectBox dataSource={localeData}
                               label={"Language"}
                               labelMode={"static"}
                               valueExpr={"id"}
                               displayExpr={"name"}
                               value={model.getLocale()}
                               visible={localeData.length >= 2}
                               onValueChanged={(e) => {
                                   setLocale(e.value);
                               }}/>
                </Row>
                <Row>
                    <Survey.Survey model={model}/>
                </Row>
            </Container>
        </>
    )
}

