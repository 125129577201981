import React from "react";
import {Link} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import logo from "./logo.svg";
import "./Utilities.css";
import {AdminLinks, UserLinks} from "./HeaderLinks";
import {SignOutButton} from "../auth/Buttons";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

export function AdminHeader() {
    return (
        <>
            <Navbar bg="idc" variant="dark" expand="lg" collapseOnSelect={true}>
                <Container>
                    <Navbar.Brand className={"text-white"} as={Link} to={'/'}>
                        <img
                            alt="IDC"
                            src={logo}
                            width="83"
                            className="d-inline-block"
                        />{' '}
                        AnswerMe
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                    <Navbar.Collapse>
                        <AuthenticatedTemplate>
                            <AdminLinks/>
                            <Nav>
                                <SignOutButton/>
                            </Nav>
                        </AuthenticatedTemplate>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {process.env.NODE_ENV !== 'production' && <span className={"debug-banner"}>Development build. Please report any bugs to the IT Development Team.</span>}
        </>
    )
}

export function UserHeader() {
    return (
        <>
            <Navbar bg="idc" variant="dark" expand="lg" collapseOnSelect={true}>
                <Container>
                    <Navbar.Brand>
                        <img
                            alt="IDC"
                            src={logo}
                            width="83"
                            className="d-inline-block"
                        />{' '}
                        AnswerMe
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                    <Navbar.Collapse>
                        <AuthenticatedTemplate>
                            <UserLinks/>
                            <Nav>
                                <SignOutButton/>
                            </Nav>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <UserLinks/>
                        </UnauthenticatedTemplate>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {process.env.NODE_ENV !== 'production' && <span className={"debug-banner"}>Development build. Please report any bugs to the IT Development Team.</span>}
        </>
    )
}