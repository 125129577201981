import React from "react";
import Row from "react-bootstrap/Row";
import ShareGroups from "./share/ShareGroups";
import ShareUsers from "./share/ShareUsers";

export default function SurveyShare(props) {
    return (
        <>
            <h5>Survey was shared with:</h5>
            <hr/>
            <Row>
                <ShareUsers id={props.surveyData.id}/>
            </Row>
            <br/>
            <hr/>
            <br/>
            <Row>
                <ShareGroups id={props.surveyData.id}
                             headers={props.headers}
                             initial_groups={props.surveyData.group}/>
            </Row>
        </>
    )
}