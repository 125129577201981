import React from "react";
import axios from "axios";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../auth/AuthCfg";
import notify from "devextreme/ui/notify";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export function getSurveyLocales(locales, default_locale) {
    let survey_languages = {
        "": "Default Language",
        "ar": "العربية",
        "eu": "Euskara",
        "bg": "български",
        "ca": "Català",
        "hr": "Hrvatski",
        "cs": "Čeština",
        "da": "Dansk",
        "nl": "Nederlands",
        "et": "Eesti keel",
        "fi": "Suomi",
        "fr": "Français",
        "ka": "ქართული",
        "de": "Deutsch",
        "gr": "Eλληνικά",
        "he": "עברית",
        "hi": "Hindi",
        "hu": "Magyar",
        "is": "Islenska",
        "id": "Bahasa Indonesia",
        "it": "Italiano",
        "jp": "日本語",
        "kk": "Kazakh",
        "ko": "한국어",
        "lv": "Latviešu",
        "lt": "Lietuvių",
        "mk": "Македонски",
        "ms": "Melayu",
        "nl-BE": "Vlaams",
        "no": "Norsk",
        "fa": "فارْسِى",
        "pl": "Polski",
        "pt-br": "Português brasileiro",
        "pt": "Português",
        "ro": "Română",
        "ru": "Pусский",
        "rs": "Srpski",
        "zh-cn": "简体中文",
        "sk": "Slovenčina",
        "es": "Español",
        "sw": "Swahili",
        "sv": "Svenska",
        "tg": "Тоҷикӣ",
        "tel": "Telugu",
        "th": "ไทย",
        "zh-tw": "繁體中文",
        "tr": "Türkçe",
        "ua": "Yкраїнська",
        "vi": "Việt Nam",
        "cy": "Cymraeg"
    };
    let available_locales = [];
    if (locales.length < 2)
        return [{
            "id": default_locale,
            "name": survey_languages[default_locale]
        }];
    locales.forEach((l) => {
        if (l === "en" && default_locale === "") {
            available_locales.push({
                "id": default_locale,
                "name": survey_languages[default_locale]
            });
        } else {
            available_locales.push({
                "id": l,
                "name": survey_languages[l]
            });
        }
    })
    return available_locales
}

export function getUserLocale(locales = []) {
    let user_locale = window.navigator.language.toLowerCase();
    if (user_locale.includes('-', 2)) {
        if (locales.length === 0)
            return user_locale;
        switch (user_locale) {
            case 'pt-br':
                // Fallback to Portuguese (Portugal)
                return locales.includes('pt-br') ? user_locale : user_locale.split('-')[0];
            case 'nl-be':
                // Fallback to standard Dutch. SurveyJS code uses uppercase for BE.
                return locales.includes('nl-BE') ? 'nl-BE' : user_locale.split('-')[0];
            default:
                // No fallback for zh-tw/zh-cn
                return user_locale
        }
    }
    return user_locale;
}

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function setCookie(name, value, max_age = 86400) {
    // name: cookie name
    // value: cookie value
    // max_age: expiration from creation date in years. Defaults to one day from now.
    document.cookie = `${name}=${encodeURIComponent(value)}; max-age=${max_age}; path=/; secure; samesite=lax;`;
}

export function delCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}

function authAPI(token, func) {
    const API_LOGIN_URL = process.env.REACT_APP_API_URL + "/api/v1/auth/msoauthtwo/";
    const API_STATE_URL = process.env.REACT_APP_API_URL + "/api/v1/auth/state/";
    axios.get(API_STATE_URL).then((res) => {
        if (res.data.detail !== "OK") {
            axios.post(API_LOGIN_URL, {
                access_token: token
            }).then((res) => {
                setCookie('csrftoken', res.data.csrf);
                func(res.data.user);
            }).catch((err) => {
                console.error(err.response);
            });
        } else {
            setCookie('csrftoken', res.data.csrf);
            func(res.data.user);
        }
    }).catch((err) => {
        notify("Unable to connect to API.", "error", 5000);
        console.error(err.response);
    })

}

export function useAuthToAPI(func = () => {
    console.info("Authenticated");
}, deps = [], force_login = true) {
    const {instance, accounts, inProgress} = useMsal();
    const request = {
        ...loginRequest,
        account: accounts[0]
    };
    useEffect(() => {
        if (accounts.length === 0 && !force_login)
            return
        instance.initialize().then(() => {
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(request).then((response) => {
                authAPI(response.accessToken, func);
            }).catch((e) => {
                delCookie('csrftoken'); // Clear Django session cookie
                instance.acquireTokenRedirect(request);
            });
        })
    }, deps);
}

export function useQuery() {
    // Parse URL params and turn them into JS' URLSearchParams
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}